const ENGLISH_STRINGS = {
  analytics: 'Analytics',
  analytic: 'Analytic',
  batches: 'Batches',
  controls: 'Controls',
  createAccount: 'Create Account',
  createUser: 'Create User',
  featureFlags: 'Feature Flags',
  indexSets: 'Index Sets',
  labApp: 'Lab App',
  logout: 'Logout',
  manageAgencies: 'Manage Agencies',
  manageAgency: 'Manage Agency',
  manageUsers: 'Manage Users',
  myAccounts: 'My Accounts',
  orders: 'Orders',
  payments: 'Payments',
  plates: 'Plates',
  prep: 'Prep',
  recommendationSets: 'Recommendation Sets',
  results: 'Results',
  rdAttributes: 'R&D Attribiutes',
  rdProtocols: 'R&D Protocols',
  samplePricing: 'Sample Pricing',
  samples: 'Samples',
  sequencedData: 'Sequenced Data',
  settings: 'Settings',
  shippingLabels: 'Shipping Labels',
  viewDemo: 'View Demo',
  webApp: 'Web App',
  searchAccount: 'Search for An Account',
  enterAccountName: 'Enter account name',
  viewDemoAccount: 'View Demo Account',
  orYouCan: 'Or you can',
  viewAllAccounts: 'View All Accounts',
  viewMyAccounts: 'View My Accounts',
  searchByUserAgency: 'Search by user/agency',
  searchByUser: 'Search by user',
  demoAccount: 'Demo Account',
  manageOrders: 'Manage Orders',
  viewResults: 'View Results',
  editAccount: 'Edit Account',
  activeSamplingPlans: 'Active Sampling Plans (Acres)',
  accountName: 'Account Name',
  fields: 'Fields',
  field: 'Field',
  unassignedPlans: 'Unassigned Plans',
  waitingToSample: 'Waiting to Sample',
  readyToSample: 'Ready To Sample',
  ordered: 'Ordered',
  notOrdered: 'Not Ordered',
  created: 'Created',
  sampled: 'Sampled',
  received: 'Received',
  arrived: 'Arrived',
  sterilized: 'Sterilized',
  partial: 'Partial',
  shipped: 'Shipped',
  completed: 'Completed',
  inProgress: 'In Progress',
  notStarted: 'Not Started',
  complete: 'Complete',
  cancelled: 'Cancelled',
  unassigned: 'Unassigned',
  processed: 'Processed',
  wontSample: "Won't Sample",
  partialResults: 'Partial Results',
  completedResults: 'Completed Results',
  pressure: 'Pressure',
  pressurePanel: 'Pressure Panel',
  pressureNutrientPanel: 'Pressure Panel + Nutrient Panel',
  performance: 'Performance',
  performancePanel: 'Performance Panel',
  perfPanel: 'Perf Panel',
  nutrientPanel: 'Nutrient Panel',
  nitratePanel: 'Nitrate Panel',
  dashboard: 'Dashboard',
  rootworm: 'Rootworm',
  scn: 'SCN',
  analysis: 'Analysis',
  eta: 'ETA',
  pending: 'Pending',
  none: 'None',
  actions: 'Actions',
  allAccounts: 'All Accounts',
  mapFields: 'Map Fields',
  uploadBoundaries: 'Upload Boundaries',
  manageAccount: 'Manage Account',
  markPlanDelivered: 'Mark Plans Delivered',
  setupASamplePlan:
    'To set up a sample plan, you need to map field boundaries. You can do this a number of ways:',
  drawBoundariesOnMap: 'Draw boundaries on a map',
  uploadShapefilesData: 'Upload shapefiles or planting / harvest data',
  mapFieldsToOrder: 'Map fields to place an order',
  toOrderFirstPlan: 'To order your first sample plan, click ',
  orderSamplePlan: 'Order Sample Plan',
  orderAdditionalSamplePlan: 'Add Additional Sample Plan',
  orderSample: 'Order Sample',
  cancelSample: 'Cancel Sample',
  editSamplePlan: 'Edit Sample Plan',
  belowForMappedFields: ' below for any of your mapped fields:',
  samplePlan: 'Sample Plan',
  status: 'Status',
  samplePlanCancelledSuccess: 'Sampling plan cancelled!',
  errorCancellingPlan: 'Error cancelling sampling plan. Please try again later.',
  shapefileDownloadSuccess: 'Shapefile download has been completed successfully',
  errorDownloadingShpfile: 'Error downloading shapefile. Try again later.',
  markNotReady: 'Mark Not Ready',
  sureMarkNotReady: 'Are you sure you want to mark the plan "Not Ready"?',
  showPastPlans: 'Show Past Sample Plans',
  editFieldBoundary: 'Edit Field Boundary',
  editFieldBoundaries: 'Edit Field Boundaries',
  downloadShpfile: 'Download Shapefile',
  viewSampleStatus: 'View Sample Status',
  errorMarkingPlanDelivered: 'Error Marking Sampling Plans as Delivered. Try again.',
  deliveryComplete: 'Delivery Complete for',
  deliverCompletePar:
    'This operation has sample plans with results that have not been yet marked as delivered to the customer. If you have completed delivery of those results, you can mark this operation complete now.',
  cancel: 'Cancel',
  loading: 'Loading',
  markAsDelivered: 'Mark as Delivered',
  orderSamplingPlan: 'Order Sampling Plan',
  assignTo: 'Assign to ',
  markReady: 'Mark Ready',
  markReadyMsg: 'Clicking this button will mark this field as Ready to Sample',
  reassign: 'Reassign',
  assign: 'Assign',
  assignSampler: 'Assign Sampler',
  state: 'State',
  assignSamplerPar: 'Select the Sampler you would like to assign to this sample plan',
  searchSampler: 'Search sampler',
  assignAllPlans: 'Assign all open sample plans on this account to this sampler',
  inviteSampler: 'Invite Sampler',
  fieldName: 'Field name',
  create: 'Create',
  edit: 'Edit',
  fieldAlreadyExists: 'A field with this name has already been created for your operation.',
  save: 'Save',
  recenter: 'Recenter',
  mapSearchPlaceholder: 'Search city, county etc',
  drawPolygon: 'Draw Polygon',
  drawRectangle: 'Draw Rectangle',
  delete: 'Delete',
  downloadRunSheet: 'Download Run Sheet',
  runSheetDownloadFailed: 'Failed to download run sheet. Try again later.',
  invalidFile: 'Invalid File Uploaded',
  uploadResults: 'Upload Results',
  uploadCsv: 'Upload CSV File',
  backToResults: 'Back to Results',
  print: 'Print',
  shapefileDownloadMsg:
    'This can take 20 minutes or more. Feel free to leave the page. (Link will appear in Download dropdown when available.)',
  download: 'Download',
  downloadCsvResults: 'Download CSV Results',
  printSummary: 'Print Summary',
  generateShapefileResults: 'Generate Shapefile Results',
  generateMapbookResults: 'Generate Mapbook Results',
  legend: 'Legend',
  highRisk: 'High Risk',
  moderateRisk: 'Moderate Risk',
  modRisk: 'Mod Risk',
  lowRisk: 'Low Risk',
  awaitingResults: 'Awaiting Results',
  notAnalyzed: 'Not Analyzed',
  noData: 'No Data',
  'crop-protection': 'Crop Protection',
  bioactive: 'Biofertility',
  nutrients: 'Nutrients',
  bcsr: 'BCSR',
  rx: 'Rx',
  beneficialBioMarkers: 'Beneficial Biomarkers (higher is better)',
  harmfulBiomarkers: 'Harmful Biomarkers (lower is better)',
  macronutrients: 'Macronutrients',
  micronutrients: 'Micronutrients',
  cationRatios: 'Cation Ratios',
  viewDetails: 'View Details',
  avg: 'Avg',
  range: 'Range',
  acAtRisk: 'Ac. at Risk',
  ranges: 'Ranges',
  low: 'Low',
  mod: 'Mod',
  moderate: 'Moderate',
  high: 'High',
  recommendation: 'Recommendation',
  risk: 'Risk',
  createNutrientScript: 'Create Nutrient Script',
  remove: 'Remove',
  nutrient: 'Nutrient',
  plannedCrop: 'Planned Crop',
  input: 'Input',
  formula: 'Formula',
  fieldRate: 'Field Rate',
  totalApplied: 'Total Applied',
  targetYield: 'Target Yield',
  fieldCost: 'Field Cost',
  minimumRate: 'Minimum rate',
  maximumRate: 'Maximum rate',
  rxName: 'Rx Name',
  generateRxFrom: 'Generate Rx from',
  createAndEditRx: 'Create and Edit Rx',
  createNutrientRx: 'Create Nutrient Rx',
  backToFieldSummary: 'Back To Field Summary',
  allResults: 'All Results',
  nutrientRx: 'Nutrient Rx',
  prescriptionCreatedMsg: 'Prescription created!',
  summary: 'Summary',
  fieldAvg: 'Field Avg',
  zoneDetails: 'Zone Details',
  zonesAtRisk: 'zones at risk',
  fieldComparison: 'Field Comparison',
  analyticDetails: 'Analytic Details',
  rating: 'Rating',
  zone: 'Zone',
  ele: 'Ele',
  average: 'Average',
  level: 'Level',
  levels: 'Levels',
  noUnits: 'no units',
  fieldsLower: 'field(s)',
  ranging: 'ranging',
  yieldImpact: 'Yield Impact',
  effectOfEnvironment: 'Effect Of Environment',
  filename: 'Filename',
  notes: 'Notes',
  createdAt: 'Created At',
  sampleRisk: 'Sample Risk',
  insufficientDataFound: 'Insufficient Data Found',
  perSampleIndicates: 'per sample indicates',
  optimal: 'Optimal',
  benchmark: 'Benchmark',
  sample: 'Sample',
  levelsIndicateModerateRisk: 'levels indicate moderate risk',
  levelsIndicateHighRisk: 'levels indicate higher risk',
  levelsIndicateYieldLoss: 'levels indicate risk for yield loss',
  highRiskRwFieldTooltip:
    'Detection of 6+ eggs indicates high risk for root feeding. Likely ranges from 0.5-2.5 node injury average without protection.',
  moderateRiskRwFieldTooltip:
    'Detection from 0.2-6 eggs indicates moderate risk of economic damage. Likely ranges from 0.25-0.7 node injury average without protection.',
  moderateRiskRwZoneTooltip:
    'Detection of 0-6 eggs indicates moderate risk of economic damage. Likely ranges from 0.25-0.7 node injury average without protection.',
  lowRiskRwFieldTooltip:
    'Detection of less than 0.2 eggs indicates low risk of economic damage. Likely less than 0.25 node injury average.',
  lowRiskRwZoneTooltip:
    'No detection of eggs indicates low risk of economic damage. Likely less than 0.25 node injury average.',
  createFarmAccount: 'Create a new farm account',
  whatsTheNameOfAccount: "What's the name of the farming account?",
  whatsTheNameOfAccountTooltip: 'This could be a farm name, or an individual.',
  boundariesAndData: 'Boundaries and Data',
  twoWaysToUpload: 'Two ways to upload field boundaries and data',
  connectOutsideAccounts: 'Connect Outside Accounts',
  uploadShapefiles: 'Upload Shapefiles',
  uploadShapefilesOfYourFields:
    'Upload shapefiles of your fields here - make sure you include all files (.shp, .shx, .dbf, etc/) and group those files in a single zip file',
  uploadZipFile: 'Upload Zip File',
  uploadZipTip: 'The uploaded file must be in a .zip or .gz format.',
  completeIngest: 'Complete Ingest',
  discard: 'Discard',
  cancelIngest: 'Cancel Ingest',
  pastFileUpload: 'Past file upload',
  upload: 'Upload',
  connectOutsideAccountsImport:
    'Connect to outside accounts to easily import field boundaries and data layers into Pattern Ag',
  serverErrorMsg: 'An error occurred with the server, please refresh and try again.',
  patternIsSyncing: 'Pattern Ag is syncing with your account. (This can take a few minutes)',
  pleaseRefreshUnableToConnect: 'Please Refresh and try again. We were unable to connect you to',
  lastSync: 'Last Sync',
  notSyncedYet: 'Not Synced yet',
  connecting: 'Connecting',
  connect: 'Connect',
  connectYourDataFrom: 'Connect your data from',
  role: 'Role',
  admin: 'Admin',
  operator: 'Operator',
  sampler: 'Sampler',
  lab: 'Lab',
  superadmin: 'Super Admin',
  agent: 'Agent',
  agency_admin: 'Agency Admin',
  businessName: 'Business Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  cellPhone: 'Cell Phone',
  billingAddress: 'Billing Address',
  city: 'City',
  zipcode: 'Zipcode',
  excludeContractSignature: 'Exclude Contract Signature?',
  isThisPartOfExisting: 'Is this part of an existing agency?',
  yes: 'Yes',
  noWeNeedToCreate: 'No, we need to create a new agency for them.',
  agentsMustBeAssignedToExisting:
    'Agents must be assigned to an existing Agency. Search for the Agency below. If you want to create a new Agency, you will need to assign the role of Agency Admin to this user',
  userWillBeAssignedTip:
    "Note: The user being created will be assigned as the 'Primary Agent' for this Agency which means their contact information will be used for billing purposes.",
  agencyName: 'Agency Name',
  searchAgencyName: 'Search Agency Name',
  thisFieldIsRequired: 'This field is required.',
  enterValidEmail: 'Enter a valid email address.',
  enterValidPhone: 'Enter a valid phone number.',
  enterAgencyName: 'Enter an agency name.',
  addAnAgency: 'Please add an agency.',
  searchUsers: 'Search Users',
  agency: 'Agency',
  searchAgency: 'Search Agency',
  agents: 'Agents',
  primaryAgent: 'Primary Agent',
  phone: 'Phone',
  searchPayments: 'Search Payments',
  user: 'User',
  purchase: 'Purchases',
  earnings: 'Earnings',
  other: 'Other',
  balance: 'Balance',
  open: 'Open',
  approved: 'Approved',
  youHaveNoApprovedMsg:
    "You have no 'Approved' payments. To view 'Open' payments, click the 'Open' selector above.",
  payorPayee: 'Payor / Payee',
  type: 'Type',
  amount: 'Amount',
  updated: 'Updated',
  invoice: 'Invoice',
  payment_due: 'Payment Due',
  settled: 'Settled',
  payment: 'Payment',
  markSettled: 'Mark Settled',
  lineItemDetail: 'Line Item Detail',
  adjustments: 'Adjustments',
  name: 'Name',
  approvedBy: 'Approved By',
  recordedBy: 'Recorded By',
  operation: 'Operation',
  acres: 'Acres',
  purchases: 'Purchases',
  netPayment: 'Net Payment',
  markSettledMsg:
    'By marking this payment as settled, you are aknowledging that this payment has been entered into Pattern Ag’s accounting records, and has been handled according to the details outlined below:',
  markRecorded: 'Mark Recorded',
  approveOpenTransactions: 'Approve Open Transactions',
  loginToQuickbooks: 'Login to Quickbooks',
  openTransactions: 'Open Transactions',
  activityDetails: 'Activity Details',
  successfullyCreatedInvoice: 'Successfully created invoice in Quickbooks.',
  successfullyCreatedNoQB: 'Successfully created invoice without sending it to Quickbooks.',
  errorApprovingTransaction: 'An error occurred when trying to approve transaction.',
  errorLoggingIn: 'Error getting login url. There may be a backend issue.',
  billingAddressRequired: 'Billing address required.',
  addAdjustments: 'Add Adjustments',
  approveTransaction: 'Approve Transaction',
  transactionApprovalMsg:
    'By approving these transaction, they will be sent to accounting for processing.',
  resultInPatternPaymentMsg: 'This will result in Pattern Ag making payment to',
  resultInPatternInvoiceMsg: 'This will result in Pattern Ag invoicing',
  addAdjustmentsFor: 'Add Adjustment for',
  description: 'Description',
  reasonForAdjustments: 'What is the reason for the adjustment?',
  adjustmentAmount: 'Adjustment Amount',
  paysPatternAg: 'Pays Pattern Ag',
  patternAgPays: 'Pattern Ag Pays',
  addAdjustment: 'Add Adjustment',
  shippingLabelsInProgress: 'Shipping Labels in Progress',
  createNewLabel: 'Create New Shipping Label',
  samplePlans: 'Sample Plans',
  labels: 'Labels',
  operations: 'Operations',
  printAll: 'Print All',
  lastUpdated: 'Last Updated',
  location: 'Location',
  printLabel: 'Print Label',
  trackPackage: 'Track Package',
  viewEditLabel: 'View/Edit Label',
  cancelLabel: 'Cancel Label',
  shippingLabel: 'Shipping Label',
  affectAllLabelsMsg: 'This will affect all labels in this group.',
  shippingFrom: 'Shipping From',
  addAddress: 'Add Address',
  parcelDimensions: 'Parcel Dimensions',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  weight: 'Weight',
  samplePlansAwaiting: 'Sample Plans Awaiting Shipment',
  searchByAccount: 'Search By Account',
  account: 'Account',
  inShipment: 'In Shipment',
  createShippingLabels: 'Create Shipping labels',
  createShippingAddress: 'Create Shipping Address',
  street: 'Street',
  addressCreatedMsg: 'Address updated successfully!',
  numberOfLabels: 'Number of labels',
  fillOutRequiredFields: 'Please fill out all required fields before submitting.',
  contactInfoUpdated: 'Contact information updated!',
  errorContactInfo: 'Error while updating contact information.',
  billingInfoUpdated: 'Billing information updated!',
  errorBillingInfo: 'Error while updating billing information.',
  profileSettings: 'Profile Settings',
  contactInfo: 'Contact Information',
  changePassword: 'Change Password',
  errorPasswordChange: 'Password change failed.',
  incorrectPassword: 'The Old Password you entered is incorrect.',
  oldPassMissing: 'Old password is missing.',
  newPassMissing: 'New password is missing.',
  newPassConfMissing: 'New password confirmation is missing.',
  existingPassNoReuse: 'Existing password cannot be reused.',
  errorNewPassRules: 'The New Password provided does not match the password rules below.',
  errorNewPassConf: 'The New Password Confirmation does not match the New Password.',
  update: 'Update',
  password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  passwordRules: 'Note: Must contain an uppercase letter, lowercase letter, and a number',
  newPassConf: 'New Password Confirmation',
  saveBillingInfo: 'Save Billing Info',
  invalidZipcode: 'Invalid zip code',
  signIn: 'Sign In',
  authFailed: 'Authentication Failed',
  forgotPassword: 'Forgot password?',
  requestFailed: 'Request failed. Please try again later.',
  emailAddress: 'Email address',
  willReceiveLinkMsg: 'You will receive an email with a link to reset your password',
  sendRequestInstructions: 'Send Reset Instructions',
  analysisPackage: 'Analysis Package',
  zones: 'Zones',
  per: 'Per',
  sampleExecution: 'Sample Execution',
  sampledBy: 'Sampled By',
  samplingTiming: 'Sampling Timing',
  planConfMsg:
    "By clicking 'Confirm Order' you are agreeing to purchase the services outlined above for this operation. Payment will be due 2 weeks after results are made available.",
  markedReadyAssignedMsg: 'When marked ready, a sampler will be assigned',
  confirmOrder: 'Confirm Order',
  sampling: 'Sampling',
  samplePlanDetails: 'Sample Plan Details',
  samplingZones: 'Sampling Zones',
  assignSamplePlan: 'Assign Sample Plan',
  confirmAnalysis: 'Confirm Analysis',
  planZonesSuccessMsg: 'Zones have successfully been updated!',
  planZonesErrorDiffMsg: 'You may have uploaded a different field.',
  planZonesErrorNoZonesMsg: 'This upload does not contain zones.',
  planZonesErrorNoZipMsg: 'You must upload a zipped file containing a ".shp" file.',
  ssurgo: 'Soil Type (SSURGO)',
  grid_zones: 'Grid Zones',
  grid_points: 'Grid Points',
  upload_zones: 'Upload Zones',
  custom_points: 'Custom Points',
  acresPerZone: 'Acres per zone',
  generating: 'Generating',
  reselectDensity: 'Reselect Density',
  uploading: 'Uploading',
  editZones: 'Edit Zones',
  resetMap: 'Reset Map',
  generateZones: 'Generate Zones',
  uploadZones: 'Upload Zones',
  lockZones: 'Lock Zones',
  noZonesSelected: 'No zones selected for analysis',
  density: 'Density',
  by: 'by',
  noAnalysis: 'No analysis',
  sampleTiming: 'Sample timing',
  allZones: 'All Zones',
  nutrient_panel_description:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter',
  nutrient_panel_description_plus_nitrate:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter, plus Nitrate testing',
  restricted_panel_description:
    'Nutrient analysis is not available for restricted soil at this time',
  pressure_panel_description:
    'Predict and plan for Rootworm, Soybean Cyst Nematode, and Sudden Death Syndrome by measuring your pressure before planting',
  pressure_panel_description_rkn:
    'Predict and plan for Root Knot Nematode, Soybean Cyst Nematode, and Sudden Death Syndrome by measuring your pressure before planting',
  performance_panel_description:
    "Map your soil's disease risk, and biofertility opportunity, to optimize input spend",
  biological_analysis: 'Biological Analysis',
  nutrient_analysis: 'Nutrient Analysis',
  splitDensityForBio: 'Split density for biological + nutrient analysis',
  splitDensityDesc:
    'Split density allows you to run biological analysis at a lower density than nutrient analysis. Both Pressure Panel and Performance Panel will be included for any selected biological zones.',
  splitDensity: 'Split Density',
  splitDensityBarcode: 'Split Density Barcode',
  order: 'Order',
  rotate: 'Rotate',
  merge: 'Merge',
  mergeZones: 'Merge Zones',
  split: 'Split',
  splitZones: 'Split Zones',
  points: 'Points',
  deleteZonesSuccess: 'Successfully deleted zones!',
  splitZonesSuccess: 'Successfully split zones!',
  splitEveryZoneError: 'Could not split every zone.',
  selectZonesToMerge: 'Select zones to merge.',
  mergeZonesSuccess: 'Successfully merged zones!',
  invalidZoneComboError: 'Invalid zone combination.',
  couldNotMergeSelectedError: 'Could not merge selected zones.',
  unableToDisplayZoneGroupingError: 'Unable to display zone groupings.',
  total: 'Total',
  noDiscount: 'No Discount',
  createZonesInOrderToBegin: 'Create zones in order to begin selecting analysis!',
  paymentTerms: 'Payment Terms',
  earliestSample: 'Earliest Sample',
  resultsDownloadSuccess: 'Results download has been completed successfully.',
  resultsDownloadFailure: 'Error downloading results. Try again later.',
  downloadResults: 'Download Results',
  generateMapbookMsg:
    'Generating mapbook... This will take a few minutes. The download will appear in the dropdown.',
  regulatedSoil: 'Regulated Soil',
  regulatedSoilMsg:
    'If this sample plan contains regulated soil, please review the required protocols and ensure that you are completing each step prior to shipment',
  containsRegulatedSoilMsg: 'Contains Regulated Soil (Please include DSCA number)',
  failedToDownloadRegSoilMsg: 'Failed to download regulated soil PDF.',
  failedGetRegulatedSoilMsg: 'Unable to get regulated soil PDF.',
  successCreatedShippingLabelBatch:
    'Successfully created shipping label batch. Your download will be ready in a few minutes.',
  errorCreatingShippingLabel: 'Error while creating shipping label.',
  noResultsExistForOperation: 'No results exist for this operation.',
  averageAppliedToZone: 'Field average applied to zone',
  dscaInShipment:
    "Shipments of regulated soil must also include the following packing slip, which contains Pattern Ag's DSCA number",
  editOperation: 'Edit Operation',
  operationDetails: 'Operation Details',
  recommendationDefaults: 'Recommendation Defaults',
  confirmFieldRemoval: 'Confirm Field Removal',
  removeFieldMsg:
    'Removing this field from your operation will mean the field and its associated data is no longer available to you. This cannot be undone.',
  confirmRemoveFieldMsg: 'Are you sure you want to remove this field?',
  invite: 'Invite',
  inviteByEmail: 'Invite users by email',
  enterAnEmail: 'Enter an email',
  excludeFromAnalysis: 'Exclude from analysis',
  leaveOperation: 'Leave Operation',
  removeAccess: 'Remove Access',
  removeBillingResponsibility: 'Remove Billing Responsibility',
  assignedBillingMsg:
    'The user assigned billing responsibility has not signed their agreement with Pattern. They can access and sign the agreement by logging in to their account at www.pattern.app. You will be able to create sample plans for this account, but will not be able collect samples in field until the agreement has been signed, or an alternative user is added for billing responsibility.',
  contractStatus: 'Contract Status',
  signed: 'Signed',
  removeBillingPartyMsg:
    'Are you sure you want to remove this user from billing responsibility for this account? Without a user responsible for billing on the account, you will not be able to collect samples in the field.',
  commission: 'Commission',
  checkingForExistingPlan: 'Checking for an existing sampling plan',
  createNewAgent: 'Create New Agent',
  inviteAgentCreateAcctMsg:
    'Invite an agent to create an account and work with your agency customers',
  thatEmailHasAcctMsg:
    'That email already has an account with Pattern Ag. Use a different email, or contact support to have that user moved to this Agency.',
  updatedUserRole: 'Updated User Role!',
  backToAllRx: 'Back to all Rx',
  from: 'From',
  prescriptionSaved: 'Prescription saved',
  timing: 'Timing',
  crop: 'Crop',
  tillage: 'Tillage',
  pricePerTon: 'Price per ton',
  percentTargetApply: 'Percent of target to apply',
  expectedYield: 'Expected yield',
  outputFormula: 'Output format',
  export: 'Export',
  preplant: 'Pre-plant',
  starter: 'Starter',
  sidedress: 'Sidedress',
  foliar: 'Foliar',
  corn: 'Corn',
  soybeans: 'Soybeans',
  shapefile: 'Shapefile',
  conventionalTillage: 'Conventional Tillage',
  reducedNoTill: 'Reduced / No-Till',
  soilTest: 'Soil Test',
  applied: 'Applied',
  removedFromPlan: 'Removed from plan',
  sampleCancelledSuccessMsg: 'Sample has been cancelled successfully',
  sampleCancelledFailureMsg: 'Cancelling the sample failed',
  samplePlanStatus: 'Sample Plan Status',
  sampleSummary: 'Sample Summary',
  sampleStatus: 'Sample Status',
  totalReads: 'Total Reads',
  action: 'Action',
  cancelEntireSample: 'Cancel Entire Sample',
  cancelAllAnalysis: 'Cancel All Analysis',
  cancelNutrient: 'Cancel Nutrient',
  cancelNitrate: 'Cancel Nitrate',
  cancelPressure: 'Cancel Pressure',
  cancelPerformance: 'Cancel Performance',
  batchSummary: 'Batch Summary',
  poolBarcode: 'Pool Barcode',
  samplesInPool: 'Samples in Pool',
  batchPoolStatus: 'Batch/Pool Status',
  noSamplesInBatch: 'No samples in batch.',
  batch: 'Batch',
  batched: 'Batched',
  plate: 'Plate',
  plated: 'Plated',
  homogenization: 'Homogenization',
  homogen: 'Homogen',
  shotgun: 'Shotgun',
  ingested: 'Ingested',
  processing: 'Processing',
  failedToFetchThresholdsMsg: 'Failed to fetch thresholds. Try again later.',
  readThresholdUpdateSuccessMsg: 'Read Threshold Update Successful',
  readThresholdUpdateFailureMsg: 'Read Threshold Update Failed',
  cancelSamplePlanFailureMsg: 'Failed to cancel sampling plan. Try again later.',
  samplePlanReadThreshold: 'Sample Plan Read Threshold',
  cancelSamplePlan: 'Cancel Sample Plan',
  cancelSamplingPlanMsg:
    'By cancelling this sample plan it will no longer be viewable on this operation.',
  sureCancelPlan: 'Are you sure you want to cancel this plan?',
  fourOFourMsg: 'It appears the page you are looking for is empty. Try one of the links above...',
  yourPasswordChanged: 'Your password has been updated!',
  login: 'Log In',
  thanksForJoiningPatter: 'Thanks for joining Pattern!',
  letsSetYourPassword: 'Let’s set your password',
  setPassword: 'Set Password',
  passwordReqMsg: 'Must contain 8 characters, including an uppercase letter and a number',
  assignSamplerNoBilling: 'Please assign a billing responsibility before the field can be sampled.',
  only: 'Only',
  no: 'No',
  weighedAndGround: 'Weighed & Ground',
  userCreatedMsg: 'User created successfully!',
  errorLoggingInMsg: 'There was an issue logging in. Please try again.',
  errorLoggedInMsg: 'Your token expired. Please log in again.',
  failedToFetchMsg: 'Failed to fetch meta data. Please refresh.',
  ssurgoOmDisclaimer:
    'In the event a zone is missing a value for OM, a weighted average from the SSURGO soil survery will be used.',
  csvDownloadMsg:
    'This can take a few minutes. Feel free to leave the page. (Link will appear in Download dropdown when available.)',
  generateCsvResults: 'Generate CSV Results',
  prescriptions: 'Prescriptions',
  successfullyRemovedPrescription: 'Successfully removed prescription',
  searchSampleMsg: 'Search for a sample by barcode or UUID',
  searching: 'Searching',
  enterBarcodeMsg: 'Enter barcode or UUID',
  errorDeletingPrescription: 'An error occurred while deleting the prescription. Please Refresh.',
  receivingDataEntry: 'Receiving & Data Entry',
  fieldAndSamplePlanDetails: 'Field & Sample Plan Details',
  sampleBarcode: 'Sample Barcode',
  mass: 'Mass',
  markArrived: 'Mark Arrived',
  markSterilized: 'Mark Sterilized',
  submit: 'Submit',
  submitting: 'Submitting',
  nitrate: 'Nitrate',
  ammoniumAcetate: 'Ammonium Acetate',
  m3Rerun: 'M3 Rerun',
  phRerun: 'pH Rerun',
  kclRerun: 'KCL Rerun',
  aaRerun: 'AA Rerun',
  omRerun: 'OM Rerun',
  shotgunQpcr: 'Shotgun + qPCR',
  primary: 'Primary',
  stateCounty: 'State & County',
  relatedRsms: 'Related RSMs',
  relatedAgencies: 'Related Agencies',
  nonPrimary: 'Non-Primary',
  createZonesUsing: 'Create zones using',
  restrictedGeography: 'Restricted Geography',
  restrictedGeographyFromMsg: `Sample is from`,
  restrictedGeogrpahyProtocolsMsg: `Follow proper handling protocols`,
  processingSummary: 'Processing Summary',
  qpcrAnalysis: 'qPCR Analysis',
  shotgunAnalysis: 'Shotgun Analysis',
  nutrientDetails: 'Nutrient Details',
  dnaExtraction: 'DNA Extraction',
  dnaExtr: 'DNA Extr',
  normalizedDna: 'Normalized DNA',
  normDna: 'Norm DNA',
  m3Analysis: 'M3 Analysis',
  phAnalysis: 'pH Analysis',
  kclAnalysis: 'KCL Analysis',
  aaAnalysis: 'AA Analysis',
  ammoniumAcetateAnalysis: 'Ammonium Acetate Analysis',
  genomic: 'Genomic',
  amplicon: 'Amplicon',
  all: 'All',
  sampleNotFound: 'Sample Not Found',
  sampleNotFoundMsg: 'No sample instance matching this barcode was found',
  source: 'Source',
  chemistryResults: 'Chemistry Results',
  noChemistryResultsMsg: 'No chemistry results for this sample uuid',
  tbd: 'TBD',
  failed: 'Failed',
  processingData: 'Processing Data',
  addSample: 'Add Sample',
  sampleMass: 'Sample Mass',
  controlType: 'Control Type',
  selectOne: 'Select One',
  sampleType: 'Sample Type',
  replicaType: 'Replica Type',
  rndSample: 'R&D Sample',
  well: 'Well',
  removeSample: 'Remove Sample',
  barcode: 'Barcode',
  barcodes: 'Barcodes',
  tags: 'Tags',
  awaitingWellInpuMsg: 'Awaiting Input For Well',
  sampleAlreadyInWellMsg: 'Sample already exists in another well, please use a replicate',
  longitude: 'Longitude',
  latitude: 'Latitude',
  samplingPlan: 'Sampling Plan',
  sampleAlreadyExistsMsg:
    "That barcode is associated with an existing sample. If this is meant to be a replicate sample, please go to 'Add Sample'. If you intend to create a new sample in the system, please choose a different barcode.",
  addControl: 'Add Control',
  createSample: 'Create Sample',
  errorSavingOperationMsg: 'Error saving operation. Please Refresh',
  operationSavedMsg: 'Operation Saved successfully!',
  errorSavingFieldMsg: 'Error saving field. Please Refresh.',
  fieldSavedMsg: 'Field Saved successfully!',
  farm: 'Farm',
  newPlate: 'New Plate',
  temp: 'Temp',
  freezer: 'Freezer',
  rack: 'Rack',
  shelf: 'Shelf',
  storage: 'Storage',
  discarded: 'Discarded',
  plateType: 'Plate Type',
  newPlateBarcode: 'New Plate Barcode',
  scanNewBarcodeMsg: 'Scan New Plate Barcode',
  sourcePlateBarcode: 'Source Plate Barcode',
  scanBarcodeMsg: 'Scan Plate Barcode',
  quadrant: 'Quadrant',
  indexSet: 'Index Set',
  target: 'Target',
  plateBarcode: 'Plate Barcode',
  noPlatesFoundMsg: 'No plates found',
  searchByBarcode: 'Search By Barcode',
  comments: 'Comments',
  requiredField: 'Required Field',
  createPlate: 'Create Plate',
  fieldDeletedMsg: 'Field deleted successfully!',
  cancelAll: 'Cancel All',
  failedDeleteLabelsMsg: 'Failed to delete labels. Please refresh and try again.',
  labelDeletedSuccessMsg: 'Labels successfully deleted!',
  deleteLabelsMsg: 'Are you sure you want to delete this label?',
  deleteLabelsGroupMsg: 'Are you sure you want to delete this group of labels?',
  label: 'Label',
  tooManyLabelsMsg: 'Please generate fewer than 80 labels at a time.',
  labId: 'Lab ID',
  generic: 'Generic',
  dnaConcentration: 'DNA Concentration',
  dilutionConcentration: 'Dillution Concentration',
  cycleTreshold: 'Cycle Threshold',
  uploadFailedMsg: 'Upload Failed.',
  uploadSuccessMsg: 'Upload Successful!',
  relatedDocuments: 'Related Documents',
  uploadedResults: 'Uploaded Results',
  provideNotesMsg: 'Provide notes for the uploaded document',
  selectFileToUploadMsg: 'Please select a file to upload',
  noFileChosen: 'No File Chosen',
  uploadDocument: 'Upload Document',
  missingUrlMsg: 'Missing upload_url',
  summarySheet: 'Summary Sheet',
  parentPlates: 'Parent Plates',
  childrenPlates: 'Children Plates',
  plateDetails: 'Plate Details',
  updateData: 'Update Data',
  exportData: 'Export Data',
  importData: 'Import Data',
  dnaConcentrationData: 'DNA Concentration Data',
  targetDilution: 'Target Dilution',
  totalDnaMultiplier: 'Total DNA Multiplier',
  pcrDnaMultiplier: 'PCR DNA Multiplier',
  recalculate: 'Recalculate',
  updateConcentrationDataMsg:
    'There is already concentration data associated with this plate. If you upload a new file it will overwrite the concentration data associated with each well. Are you sure you want to continue?',
  uploadSamples: 'Upload Samples',
  uploadSamplesCsvMsg: 'Upload Sample Wells from CSV File',
  uploadProgress: 'Upload Progress',
  doNotNavigateMsg: 'Do not navigate away from this page until upload is complete.',
  uploadAbortedMsg: 'File upload was aborted.',
  uploadErrorMsg: 'There was an error uploading file.',
  abort: 'Abort',
  noFilesListMsg: 'No files to display.',
  noPlatesListMsg: 'No plates to display.',
  confirm: 'Confirm',
  copyPlate: 'Copy Plate',
  uploadBulkPlate: 'Upload Bulk Plate',
  openPlate: 'Open Plate',
  storePlate: 'Store Plate',
  downloadDetails: 'Download Details',
  discardPlate: 'Discard Plate',
  dlSampleSheet: 'DL Sample Sheet',
  discardPlateWarningMsg:
    'Warning: If you discard this plate, it cannot be recovered. Are you sure you want to do this?',
  sourcePlates: 'Source Plates',
  qpcrCycles: 'qPCR Cycles',
  editQpcrCycles: 'Edit qPCR Cycles',
  editNotes: 'Edit Notes',
  indicatePlateStorageMsg: 'Please indicate where and how the plate will be stored',
  temperature: 'Temperature',
  coldOrRoomTemp: 'Cold or Room Temp',
  unableToSavePlanMsg: 'Unable to save plan. Please refresh and try again.',
  errorWithFieldGeometryMsg:
    'Please check your field boundaries. There may be unnecessary intersections or holes.',
  unableToLoadZonesMsg: 'Unable to load zones. Try again later.',
  errorLoadingExistingPlanMsg: 'There was an issue loading the existing plan. Please refresh.',
  cancelSamplePlanMsg: 'Are you sure you want to cancel this order?',
  samplePlanTracking: 'Sample Plan Tracking',
  planStatus: 'Plan Status',
  daysMarkedReady: 'Days Marked Ready',
  alerts: 'Alerts',
  readiness: 'Readiness',
  unassigned_ready_to_sample: 'Unassigned, Ready to Sample',
  assignment_not_accepted: 'Assignment Not Accepted',
  assignment_declined: 'Assignment Declined',
  marked_ready: 'Marked Ready',
  sampling_at_risk: 'Sampling at Risk',
  sampling_overdue: 'Sampling Overdue',
  sampled_not_shipped: 'Sampled, not shipped',
  sla_at_risk: 'SLA at Risk',
  sla_overdue: 'SLA Overdue',
  insufficient_data: 'Insufficient Data',
  'partially sampled': 'Partially Sampled',
  'partially shipped': 'Partially Shipped',
  'partially received': 'Partially Received',
  ready: 'Ready',
  assigned_not_accepted: 'Assigned, Not Accepted',
  accepted: 'Accepted',
  declined: 'Declined',
  not_ready: 'Not Ready',
  assignment_accepted: 'Assignment Accepted',
  marked_ready_days: 'Marked Ready (Days)',
  all_statuses: 'All Statuses',
  all_assignments: 'All Assignments',
  all_readiness: 'All Readiness',
  all_alerts: 'All Alerts',
  panel: 'Panel',
  hideDetails: 'Hide Details',
  goToPlan: 'Go To Plan',
  genomicProcessing: 'Genomic Processing',
  nutrientProcessing: 'Nutrient Processing',
  newBatch: 'New Batch',
  noBatchesFound: 'No Batches Found',
  plateNotAddedToBatch: 'Plate Not Added To Batch',
  plateAddedToBatch: 'Plate Added To Batch',
  showMe: 'Show Me',
  with: 'With',
  plateBarcodeId: 'Plate Barcode / ID',
  allAnalyses: 'All Analyses',
  allTypes: 'All Types',
  allStatuses: 'All Statuses',
  homogenBarcode: 'Homogen Barcode',
  createBatch: 'Create Batch',
  soil: 'Soil',
  residue: 'Residue',
  rootBall: 'Root Ball',
  rhizosphere: 'Rhizosphere',
  rootTissue: 'Root Tissue',
  isolates: 'Isolates',
  partiallyShipped: 'Partially Shipped',
  addPlate: 'Add Plate',
  totalDna: 'Total DNA',
  totalPcrDna: 'Total PCR DNA',
  'waiting to sample': 'Waiting to Sample',
  markNotReadySuccess: 'The plan was successfully marked as not ready.',
  markNotReadyFail:
    'There was an error marking the plan as not ready. Please refresh and try again.',
  assignSamplerError: 'There was an error assigning the sampler. Please refresh and try again.',
  updatingAgencyErrorMsg: 'There was an error updating the agency. Please refresh and try again.',
  updatingAgencySuccessMsg: 'The agency was successfully updated!',
  emailAlreadyExistsMsg:
    'That email already has an account with Pattern Ag. Use a different email, or contact support to have that user moved to this Agency.',
  updatingUserRoleErrorMsg:
    'There was an error updating the user role. Please refresh and try again.',
  addPlateSuccess: 'Plate Added Successfully!',
  sourcePools: 'Source Pools',
  sampleSheet: 'Sample Sheet',
  markBatchSent: 'Mark Batch Sent',
  dlNovaSeqIndexSets: 'DL NovaSeq Index Sets',
  dlMiSeqIndexSets: 'DL MiSeq Index Sets',
  dlRunSheet: 'DL Run Sheet',
  markBatchWarningMsg:
    'Please note this batch will no longer be editable and the sample sheets will be locked!',
  markBatchConfirmMsg: 'Are you sure you want to mark this batch as sent?',
  contractPricing: 'Contract Pricing',
  contractDetails: 'Contract Details',
  priceList: 'Price List',
  agencyCost: 'Agency Cost',
  listPrice: 'List Price',
  msrp: 'MSRP',
  pressure_panel: 'Pressure Panel',
  performance_panel: 'Performance Panel',
  nutrient_panel: 'Nutrient Panel',
  createContract: 'Create Contract',
  createNewContract: 'Create New Contract',
  list: 'List',
  editContract: 'Edit Contract',
  support: 'Support',
  deleteYourDataMsg:
    'For support or to request the deletion of your account and/or data, please send an email to support@pattern.ag.',
  contractUpdatedSuccessMsg: 'Contract Updated Successfully!',
  contractUpdatedErrorMsg:
    'There was an error updating the contract. Please refresh and try again.',
  committed_samples: 'Committed Samples',
  contractType: 'Contract Type',
  incentive: 'Incentive',
  discount: 'Discount',
  committedSamples: 'Committed Samples',
  signedAt: 'Signed At',
  expiredOn: 'Expired On',
  noContractsExistMsg: 'No contracts exist for this agency.',
  batchOrder: 'Batch Order',
  zymoSoil: 'Zymo Soil',
  zymo: 'Zymo',
  zymoDilution: 'Zymo Dilution',
  soilDilution: 'Soil Dilution',
  manageControls: 'Manage Controls',
  disable: 'Disable',
  enterName: 'Enter Name',
  enterDescription: 'Enter Description',
  enterCommaSeparatedValues: 'Enter Comma-Separated Values',
  dilution: 'Dilution',
  createControl: 'Create Control',
  createIndexSet: 'Create Index Set',
  noIndexSetsFound: 'No Index Sets Found',
  backToList: 'Back To List',
  forwardPrimer: 'Forward Primer',
  forwardIndexSequence: 'Forward Index Sequence',
  reversePrimer: 'Reverse Primer',
  reverseIndexSequence: 'Reverse Index Sequence',
  createAttribute: 'Create Attribute',
  rndAttributes: 'R&D Attributes',
  rndProtocols: 'R&D Protocols',
  allowedValues: 'Allowed Values',
  createProtocol: 'Create Protocol',
  dateSigned: 'Date Signed',
  expirationDate: 'Expiration Date',
  current: 'Current',
  plannedSamples: 'Planned Samples',
  samplesTaken: 'Samples Taken',
  completedSamples: 'Completed Samples',
  endContract: 'End Contract',
  contractEndedSuccessMsg: 'Contract Ended Successfully!',
  doNotEnd: 'Do Not End',
  areYouSure: 'Are you sure',
  autoSubmit: 'Auto-Submit',
  addBillingResponsibility: 'Add Billing Responsibility',
  samplingHistory: 'Sampling History',
  method: 'Method',
  manualRecord: 'Manual Record',
  manual: 'Manual',
  planName: 'Plan Name',
  empty: 'Empty',
  date: 'Date',
  shippedDate: 'Shipped Date',
  noSamplingHistoryMsg: 'No sampling history found for current year',
  partiallyFilled: 'Partially Filled',
  homogenizationComplete: 'Homogen Complete',
  insufficient_data_performance: 'Insufficient Data (Performance)',
  firstSampleArrived: 'First Sample Arrived',
  wells: 'Wells',
  or: 'Or',
  markedReadySuccessMsg: 'The plan was successfully marked ready.',
  markedReadyFailureMsg: 'There was an error marking the plan ready. Please refresh and try again.',
  samplerInstructions: 'Sampler Instructions',
  noInstructionsAvailable: 'No instructions available.',
  days_marked_ready: 'Days Marked Ready',
  commercialTerritory: 'Commercial Territory',
  clear: 'Clear',
  notTerritoryOwner:
    'Your assigned territory was not created by you. Press clear to reset the map if you would like to draw your own.',
  territoryCreated: 'Commercial Territory Saved!',
  territoryUsers: 'Territory Users',
  add: 'Add',
  myAgencies: 'My Agencies',
  territoryName: 'Territory Name',
  territory: 'Territory',
  territorySavingErrMsg: 'There was an error saving the territory. Please refresh and try again.',
  newTerritory: 'New Territory',
  users: 'Users',
  deleteTerritoryMsg: 'Are you sure you want to delete this territory?',
  territoryDeletedMsg: 'Territory Deleted Successfully!',
  owner: 'Owner',
  missingInfoMsg: 'Please fill out all information before saving.',
  insufficientDataWarning:
    'More than 25% of your field has insufficient data and you will be receiving a refund. Zones marked N/A do not have sufficient data.',
  switchUserToAgent: 'Switch User to Agent',
  selectAgencyReassignMsg: 'Select the Agency they should belong to or create a new one',
  createNewAgency: 'Create New Agency',
  newAgencyName: 'New Agency Name',
  inPayment: 'In Payment',
  uncheckAll: 'Uncheck All',
  bray: 'Bray',
  olsen: 'Olsen',
  phosphorus: 'Phosphorus',
  riskEstimate: 'Risk Estimate',
  newRiskEstimate: 'New Risk Estimate',
  benefits: 'Benefits',
  myFarmPlan: 'My Farm Plan',
  myReport: 'My Report',
  offer: 'Offer',
  overview: 'Overview',
  patternReportAnalyzes:
    'The Pattern 360 report analyzes your soil to <b>predict next year’s risk</b> from damaging pests & diseases.',
  mapYourPressure:
    '<b>Map your pressure:</b> Pattern measures your soil biology to create <b>sub-field maps of pest and disease pressure.</b>',
  predictYourRisk:
    '<b>Predict your risks:</b> Pest & disease maps <b>predicts your risk of yield loss</b> next season.',
  createYourPlan:
    '<b>Create your plan:</b> The Pattern 360 will help <b>recommend the inputs</b> that will boost yield and optimize spend.',
  seeHowItWorks: 'See How It Works',
  viewSampleReport: 'View Sample Report',
  viewBenefits: 'View Benefits',
  createFarmPlan: 'Create Farm Plan',
  viewMyReport: 'View My Report',
  viewOffer: 'View Offer',
  theTypicalMidwestFarm:
    'The typical <b>Midwest</b> farm working with Pattern can <b>boost profits by</b>',
  applyRecommendedInputs:
    "Apply recommended inputs based on Pattern 360 report on 'at risk' fields",
  optimizeInputs: "Optimize inputs based on Pattern 360 report on 'at risk' fields",
  boostYield: 'Boost yield',
  optimizeSpend: 'Optimize spend',
  startedWhichOperation: 'To get started, which operation are we working with?',
  farmName: 'Farm Name',
  primaryCounty: 'Primary County',
  countyFootNote: 'Used to estimate the risks for your operation',
  operatorName: 'Operator Name',
  optional: 'optional',
  operatorEmail: 'Operator Email',
  estimateYourRisk: 'To estimate your risk, tell us about your crop plan:',
  cornPlan: 'Corn Plan',
  soybeanPlan: 'Soybean Plan',
  traits: 'Traits',
  seedTreatment: 'Seed Treatment',
  inFurrow: 'In furrow',
  seedRisks: 'Seed Risks',
  rwStack: 'RW Stack',
  rwSingle: 'RW Single',
  aboveGroundOnly: 'Above ground only',
  basePackage: 'Base Package',
  fungicide: 'Fungicide',
  insecticide: 'Insecticide',
  fungicideInsecticide: 'Fungicide & Insecticide',
  northCornLeafBlight: 'North Corn Leaf Blight',
  grayLeafSpot: 'Gray Leaf Spot',
  gossWilt: "Goss's Wilt",
  tarSpot: 'Tar Spot',
  sds: 'SDS',
  nematicide: 'Nematicide',
  sdsFungicide: 'SDS + Fungicide',
  sdsNematicide: 'SDS + Nematicide',
  fungicideNematicide: 'Fungicide + Nematicide',
  sdsFungicideNematicide: 'SDS + Fungicide + Nematicide',
  soybeansPlan: 'Soybeans Plan',
  yield: 'Yield',
  price: 'Price',
  operationName: 'Operation Name',
  searchOperation: 'Search Operation',
  searchCounty: 'Search County',
  searchCountyError: 'An error occurred while searching for the specified county. Please Refresh.',
  totalReturn: 'Total Return',
  estimateBasedOnFarmPlan:
    "This is an estimate based on your farm plan and Pattern Ag's predicted risk for 2023 in your county.",
  patternAgPersonalizedReport:
    'Your Pattern 360 report will provide personalized analysis and recommendations for your farm.',
  estAcresAtRisk: 'Est. Acres at Risk',
  protection: 'Protection',
  estYieldAtRisk: 'Est. Yield at Risk',
  highRiskAreas: 'High Risk Areas',
  lowRiskAreas: 'Low Risk Areas',
  estYieldImpact: 'Est. Yield Impact',
  inputSavings: 'Input Savings',
  additionalInputSpend: 'Additional Input Spend',
  estReturn: 'Est. Return',
  estBenefit: 'Est. Benefit',
  cost: 'Cost',
  return: 'Return',
  lockInOfferToday: 'Lock In Offer Today',
  noRiskCancel: 'No risk, cancel anytime before sampling',
  estimatedReturnAcross: 'Estimated return across your operation',
  lockInAcresNow: 'Lock in acres now to ensure timely sampling',
  createOperation: 'Create Operation',
  createOperationErrorMsg:
    'There was an error creating the operation. Please refresh and try again.',
  createOperationSuccessMsg: 'Operation Created Successfully!',
  canUpdateLater: 'You can update this later',
  missingOperationIdMsg: 'Please create or choose an existing operation.',
  missingCountyMsg: 'Please select a county.',
  missingCropDetailsMsg: 'Please fill out all crop information.',
  cropProtectionCosts: 'Crop Protection Costs',
  revenue: 'Revenue',
  invalidCornYieldMsg: 'Please make sure your corn yield is between 100 and 600.',
  invalidSoybeanYieldMsg: 'Please make sure your soybean yield is between 20 and 100.',
  invalidCornPriceMsg: 'Please make sure your corn price is between $2 and $10.',
  invalidSoybeanPriceMsg: 'Please make sure your soybean price is between $5 and $20.',
  postOperationCropInfoErrorMsg:
    'There was an error updating the crop information. Please refresh and try again.',
  highRiskAcres: 'High Risk Acres',
  lowRiskAcres: 'Low Risk Acres',
  operationSummary: 'Operation Summary',
  language: 'Language',
  userLocaleUpdated: 'User locale updated!',
  userRoleUpdated: 'User role updated!',
  report: 'Report',
  county: 'County',
  manageAgents: 'Manage Agents',
  manageCommercialUsers: 'Manage Commercial Users',
  lockInOfferErrorMsg: 'There was an error locking in your offer. Please try again.',
  lockedInAcres: 'Locked In Acres',
  acreCommit: 'Acre Commit',
  createEstimate: 'Create Estimate',
  waiting: 'Waiting',
  foliarFungicide: 'Foliar Fungicide',
  searchAccountOrViewAll: 'Search for an Account or View All',
  locked: 'Locked',
  expired: 'Expired',
  nitrate_panel: 'Nitrate Panel',
  pressure_panel_only: 'Pressure Panel',
  complete_bio: 'Complete Bio',
  pattern_360: 'Pattern 360',
  nitrateAnalysis: 'Nitrate Analysis',
  completeBioMsg:
    "In addition to the Pressure Panel, map your soil's disease risk and biofertility opportunity, to optimize input spend",
  pattern360Msg:
    'Combine Complete Bio with our comprehensive macro and micronutrient analysis to identify nutrient deficiencies and balance your soil (not available for regulated soil at this time)',
  nitrateAnalysisMsg:
    'Optional: Add nitrate testing to each zone where nutrient analysis will be completed',
  pressureAndPerformancePanelsZones: 'Pressure & Performance Panels Zones',
  performAnalysisSingleDensity: 'Perform all analysis at a single density',
  productOrdered: 'Product Ordered',
  unableGenerateZoneGroupings: 'Unable to generate zone groupings. Please refresh.',
  printQRCode: 'Print QR Code',
  printSplitDensityBarcode: 'Print Split Density Barcode',
  discount_pressure_panel: 'Stine - Pressure Panel',
  country: 'Country',
  operationCountryMsg: 'This is the country where the operation is located.',
  shippingTo: 'Shipping To',
  labelCount: 'Label Count',
  shippingRegulatedSoil: 'Shipping Regulated Soil',
  patternAgDSCANumber: 'Pattern Ag DSCA Number',
  viewOrderSummary: 'View Order Summary',
  summaryOfSamplePlanMsg:
    'This is a summary of the sample plan as ordered. The final invoice will reflect any potential adjustments.',
  toBeSampledBy: 'To be sampled by',
  labelDownloads: 'Label Downloads',
  runPlanned: 'Run Planned',
  resultsUploaded: 'Results Uploaded',
  full: 'Full',
  estimatedCompletionDate: 'Estimated Completion Date',
  rerun: 'Rerun',
  technical: 'Technical',
  process: 'Process',
  defaultShippingLocation: 'Default Shipping Location',
  errorDefaultShippingMsg:
    'There was an error setting the default shipping location. Please try again.',
  plateHasBatchErrorMsg:
    'Plate already exists in another batch. Adding the plate to this batch will remove it from the previous batch',
  endOfResults: 'End of Results',
  predicted_ready: 'Predicted Ready',
  partially_sampled_long_duration: 'Partially Sampled (Long Duration)',
  marked_not_ready: 'Marked Not Ready',
  reads: 'Reads',
  cornPathogens: 'Corn Pathogens',
  cornAndSoybeanPathogens: 'Corn & Soybean Pathogens',
  soybeanPathogens: 'Soybean Pathogens',
  cornFoliar: 'Corn Foliar',
  soybeanFoliar: 'Soybean Foliar',
  detected: 'Detected',
  notDetected: 'Not Detected',
  interLabShipping: 'Inter-Lab Shipping',
  labShipmentId: 'Lab Shipment ID',
  to: 'To',
  regulatedMaterial: 'Regulated Material',
  regulatedMaterialMsg:
    'If shipping regulated material, ensure that ONLY regulated materials are in the shipment',
  deliveredLabels: 'Delivered Labels',
  nutrientSamples: 'Nutrient Samples',
  genomicSamples: 'Genomic Samples',
  genomicPlates: 'Genomic Plates',
  upsLabels: 'UPS Labels',
  noLabelsListMsg: 'No labels found',
  deliveryDate: 'Delivery Date',
  include: 'Include',
  noShipmentsFoundMsg: 'No Shipments Found',
  createLabShipment: 'Create Lab Shipment',
  addLabel: 'Add Label',
  shipmentCreatedMsg: 'Shipment Created Successfully',
  shipmentUpdatedMsg: 'Shipment Updated Successfully',
  saveChanges: 'Save Changes',
  unknown: 'Unknown',
  preTransit: 'Pre Transit',
  transit: 'Transit',
  delivered: 'Delivered',
  returned: 'Returned',
  failure: 'Failure',
  shipping: 'Shipping',
  shippedLabels: 'Shipped Labels',
  not_detected: 'Not Detected',
  on: 'On',
  off: 'Off',
  sugarBeets: 'Sugar Beets',
  analysisOptionsMsg: 'Analysis options updated successfully!',
  sugarBeetPestsDiseases: 'Sugar Beet Pests & Disease',
  'sugar-beets': 'Sugar Beets',
  analysisOptions: 'Analysis Options',
  dlBarcodeFile: 'DL Barcode File',
  viewAdditionalResults: 'View Additional Results',
  searchCurrentBounds: 'Search current bounds',
  showing: 'Showing',
  removeSearchBounds: 'Remove search bounds',
  cancelOrder: 'Cancel Order',
  noNitrate: 'No Nitrate',
  supportUnderTwoAcres: 'Our system does not support fields under 2.1 acres. Please resize.',
  myAlerts: 'My Alerts',
  csvColumnsRequiredMsg: 'File must contain the following columns',
  printHeader: 'Your Soil Is Talking, It’s Time To Listen',
  season: 'Season',
  productAdmin: 'Product Admin',
  bulkAssign: 'Bulk Assign',
  clickToAssignPlans: 'Click To Assign Plans',
  markNotArrived: 'Mark Not Arrived',
  mapbookConfiguration: 'Mapbook Configuration',
  english: 'English',
  portuguese: 'Portuguese',
  mapbookGenerationFailedMsg: 'Mapbook generation failed. Please try again.',
  overviewOnly: 'Overview Only',
  snoozeAlerts: 'Snooze Alerts',
  dismissAlerts: 'Dismiss Alerts',
  selectAlertsToSnooze: 'Select Alerts to Snooze',
  selectAlertsToDismiss: 'Select Alerts to Dismiss',
  updatingAlertError: 'There was an error updating the alert',
  selfSampling: 'Self Sampling',
  allowMultiplePlans: 'Allow Multiple Open Plans Per Field',
  noBillingUserMsg: 'Please add a Billing Responsibility before placing orders',
  optOutText:
    'You have successfully opted out of receiving notifications from Pattern Ag via text message. Please allow 24 hours for changes to take effect.',
  plateArrived: 'Plate Arrived',
  eggs: 'Eggs',
  'dna copies': 'DNA Copies',
  worms: 'Worms',
  noResultsForCategory: 'No results for this category.',
  not_analyzed: 'Not Analyzed',
  operationNoDiscountsMsg: 'This operation has no discount programs.',
  editDiscountProgram: 'Edit Discount Program',
  maxAcres: 'Max Acres',
  regulated: 'Regulated',
  updatePrescriptionZoneErrorMsg: 'Error updating prescription zone. Please refresh and try again.',
  updatePrescriptionZoneSuccessMsg: 'Prescription zone updated successfully!',
  john_deere: 'John Deere',
  field_view: 'Field View',
  cnhi: 'CNHI',
  sendTo: 'Send To',
  lime: 'Lime',
  potassium: 'Potassium',
  isuPhosphorus: 'ISU Phosphorus',
  isuLimeTo6: 'ISU Lime to 6.0',
  isuLimeTo65: 'ISU Lime to 6.5',
  isuLimeTo69: 'ISU Lime to 6.9',
  isuPotassium: 'ISU Potassium',
  initiatedExportFailMsg: 'Failed to initiate export. Please refresh and try again.',
  prescriptionDownloadFailMsg: 'Failed to download prescription. Please refresh and try again.',
  prescriptionDownloadSuccessMsg: 'Prescription downloaded successfully!',
  exportInitiatedMsg: 'Your export was initiated!',
  naptControls: 'NAPT Controls',
  controlCode: 'Control Code',
  createNaptControl: 'Create NAPT Control',
  assignUserToAgency: 'Assign User to Agency',
  of: 'of',
  haveReadAndAgreeToPatternAg:
    'By clicking “Agree,” I acknowledge that I have read and understand the PatternAg, Inc. End User License Agreement and Privacy Policy, and I accept and agree to be bound by all of the terms and conditions of the PatternAg, Inc. End User License Agreement:',
  haveReadAndAgreeToPatternAgIncludingTerms:
    'By clicking “Agree,” I acknowledge that I have read and understand the PatternAg, Inc. Terms and Conditions, End User License Agreement and Privacy Policy, and I accept and agree to be bound by all of the terms and conditions of the PatternAg, Inc. End User License Agreement:',
  termsAndConditions: 'Terms and Conditions',
  endUserLicenseAgreement: 'End user license agreement',
  privacyPolicy: 'Privacy Policy',
  createPassword: 'Agree and Create Password',
  noLogMeOut: 'No, log me out',
  userAgreements: 'User Agreements',
  incomplete: 'Incomplete',
  success: 'Success',
  uploadLogo: 'Upload Logo',
  triStatePhosphorus: 'Tri State Phosphorus',
  triStatePotassium: 'Tri State Potassium',
  triStateLimeTo6: 'Tri State Lime to 6.0',
  triStateLimeTo65: 'Tri State Lime to 6.5',
  triStateLimeTo68: 'Tri State Lime to 6.8',
  triStateOhLimeTo6: 'Tri State (OH) Lime to 6.0',
  triStateOhLimeTo65: 'Tri State (OH) Lime to 6.5',
  triStateOhLimeTo68: 'Tri State (OH) Lime to 6.8',
  pressure_nutrient_panels: 'Pressure Panel + Nutrient',
  pressureNutrientPanelsDescription:
    'Our Pressure Panel plus our comprehensive macro and micronutrient analysis',
  sdsuPhosphorusOlsen: 'SDSU/MN Phosphorus (OLSEN)',
  sdsuPhosphorusBray: 'SDSU/MN Phosphorus (Bray)',
  sdsuPotassium: 'SDSU/MN Potassium',
  sdsuLime: 'SDSU Lime',
  updateAddressFailMsg: 'Failed to update address. Please refresh and try again.',
  updateUserInfoFailMsg: 'Failed to update user info. Please refresh and try again.',
  invalidCellPhoneMsg: 'Please enter a valid cell phone number.',
  saveContactInfo: 'Save Contact Info',
  billingAddressMissingMsg: 'Please fill out all billing address fields.',
  lossOnIgnition: 'Loss On Ignition',
  fileSizeTooLarge: 'File size must be less than 2 MB.',
  incorrectImageDimensions: 'Image dimensions must be at least 1x1.',
  bulkDelete: 'Bulk Delete',
  bulkExport: 'Bulk Export',
  options: 'Options',
  selectAll: 'Select All',
  removeSelected: 'Remove Selected',
  exportSelected: 'Export Selected',
  bulkDeleteRxMsg: 'This will remove all selected prescriptions. Press confirm to continue.',
  bulkExportRxMsg: 'This will export all selected prescriptions. Press confirm to continue.',
  whereSendInvoiceMsg: 'Let us know where we should send any outstanding invoices for this account',
  errorSettingBillingResMsg: 'Error setting billing responsibility. Please try again.',
  performance_panel_list_low_msg: 'Complete Bio list price is too low.',
  performance_panel_list_high_msg: 'Complete Bio list price is too high.',
  nutrient_panel_list_low_msg: 'Pattern 360 list price is too low.',
  nutrient_panel_list_high_msg: 'Pattern 360 list price is too high.',
  pressure_panel_list_low_msg: 'Pressure Panel list price is too low.',
  pressure_panel_list_high_msg: 'Pressure Panel list price is too high.',
  checkBoxToContinue: 'check the box to continue',
  calciumCarbonateEquivalent: 'Calcium Carbonate Equivalent',
  relativeNeutralization: 'Relative Neutralization',
  neutralization: 'Neutralization',
  selectClus: 'Select CLUs',
  addClus: 'Add CLUs',
  reloadClus: 'Reload CLUs',
  failedToLoadClusMsg: 'Failed to load CLUs',
  cluInstructions1:
    'Select CLU(s) to begin mapping a boundary. Clicking "Reload CLUs" will refresh the boundaries after moving the map.',
  cluInstructions2: 'Click "Add CLUs" to edit selected boundaries.',
  cluInstructions3: 'Zoom in to load CLUs.',
  addFieldInstructions: 'Provide a field name and click "save" to continue.',
  addLocation: 'Add Location',
  manageAgencyLocations: 'Manage Agency Locations',
  addAgency: 'Add Agency',
  locations: 'Locations',
  manageLocations: 'Manage Locations',
  createOrAddAgencyUnder: 'Create or add an agency under',
  isExistingAgency: 'Is this an existing agency?',
  noAgenciesExist: 'No agencies exist',
  brazilSaturationMethod: 'Brazil - Saturation Method',
  targetBaseSaturation: 'Target Base Saturation',
  manageParentAgency: 'Manage Parent Agency',
  baseSaturation: 'Base Saturation',
  agencies: 'Agencies',
  deleteAgencyOperationConnection: 'Delete agency connection for',
  removeAgencyConnectionMsg:
    'This will remove the agency access from this operation. However, any agents directly connected to this operation will still have access.',
  unableToConnectAgency: 'Unable to connect agency. Please refresh and try again.',
  unableToDisconnectAgency: 'Unable to disconnect agency. Please refresh and try again.',
  agencyConnectionRemoved: 'Agency connection removed successfully!',
  agencyConnectionAdded: 'Agency connection added successfully!',
  targetPhosphorus: 'Target Phosphorus',
  brazilPhosphorus: 'Brazil - Phosphorus',
  seller: 'Seller',
  partner: 'Partner',
  pro: 'Pro',
  tooLowMsg: 'Value is too low',
  tooHighMsg: 'Value is too high',
  selectAgencyMsg: 'Select An Agency To View',
  selectAgencyToBeginMsg: 'To Start, Select An Agency From the Dropdown Above',
  allRiskLevels: 'All Risk Levels',
  analyticRiskMap: 'Analytic Risk Map',
  percentOfFieldsAtModerateOrHighRisk: 'Percent of Fields at Moderate or High Risk',
  show: 'Show',
  filteredResults: 'Filtered Results',
  totalAcres: 'Total Acres',
  fieldRisk: 'Field Risk',
  highRiskFields: 'High Risk Fields',
  moderateRiskFields: 'Moderate Risk Fields',
  lowRiskFields: 'Low Risk Fields',
  totalPlannedSamples: 'Total Planned Samples',
  riskMap: 'Risk Map',
  totalSamplesTaken: 'Total Samples Taken',
  totalCompletedSamples: 'Total Completed Samples',
  agencyContractWarningMsg: 'Billing agency contract is expired: orders will be invoiced at MSRP',
  parent_agency: 'Parent Agency',
  manage: 'Manage',
  addAgents: 'Add Agents',
  agencyUpdatedSuccessMsg: 'Agency updated successfully!',
  parentAgency: 'Parent Agency',
  agencyDefaults: 'Agency Defaults',
  displayedRecommendationSet: 'Displayed Recommendation Set',
  value: 'Value',
  new: 'New',
  newOrParentContract: 'New or Parent Contract?',
  next: 'Next',
  enterEmailToSignIn: 'Enter your email to sign in',
  noUserFoundMsg: 'No user found with that email.',
  howWouldyouLikeToSignIn: 'How would you like to sign in?',
  signInWithPassword: 'Sign in with password',
  sendCodeTo: 'Send code to',
  emailCodeTo: 'Email code to',
  goBack: 'Go back',
  codeSentSuccessMsg: 'Code sent successfully!',
  codeSentFailMsg: 'There was an error sending the code. Please refresh and try again.',
  welcomeBack: 'Welcome back',
  enterCodeSentTo: 'Enter the 6-digit code sent to',
  resendCode: 'Resend code',
  signInDifferentWay: 'Sign in a different way',
  enterPasswordFor: 'Enter password for',
  splitDensityGroup: 'Split Density Group',
  biologicalSubsample: 'Biological Subsample',
  codeIncorrectMsg: 'This code is incorrect or has expired. Please resend and try again.',
  mergeFields: 'Merge Fields',
  splitFields: 'Split Fields',
  selectFieldsToMerge: 'Select field boundaries to merge that have not had previous sample plans.',
  confirmMerge: 'Confirm Merge',
  cancelMerge: 'Cancel Merge',
  fieldsMergedSuccessMsg: 'Fields merged successfully!',
  manageFields: 'Manage Fields',
  confirmSplit: 'Confirm Split',
  cancelSplit: 'Cancel Split',
  newFieldName: 'New Field Name',
  fieldSplitSuccessMsg: 'Field split successfully!',
  missingSelectionOverlapMsg: 'Missing overlap between field and selection.',
  selectFieldsToSplit: 'Select field boundaries to split that have not had previous sample plans.',
  selectDiscountProgram: 'Select a Discount Program',
  programToEnrollMsg: 'Which program do you want to enroll?',
  maxAcresForEnrollment: 'Max Acres for enrollment',
  discount_sampling_fee: 'Discount Sampling Fee',
  discount_panels: 'Discount Panels',
  'pagi-2024': 'PAGI',
  max: 'Max',
  moisture: 'Moisture',
  addEditBoundaries: 'Add / Edit Boundaries',
  lane: 'Lane',
  orderHistory: 'Order History',
  panelsOrdered: 'Panels Ordered',
  pendingDelivery: 'Pending Delivery',
  planMarkedDeliveredSuccess: 'Plans marked as delivered successfully!',
  noOrderHistoryMsg: 'No order history found for current year',
  removeAgencyLocation: 'Remove Agency/Location?',
  removeAgencyLocationWarning: 'This will remove the agency/location connection. Are you sure?',
  printOptions: 'Print Options',
  mapbookDownloads: 'Mapbook Downloads',
  downloadOptions: 'Download Options',
  noResultsExistForField: 'No results exist for this field.',
  sikora: 'Sikora',
  deleteTerritory: 'Delete Territory',
  addTargetYieldMsg: 'Please add a target yield to continue.',
  updateFieldCropErrorMsg: 'Error updating field crop plan. Please refresh and try again.',
  updateFieldCropSuccessMsg: 'Field crop plan updated successfully!',
  noDataForFieldAnalytic: 'There is no data for this field/analytic.',
  sugar_beets: 'Sugar Beets',
  planning: 'Planning',
  billingAgency: 'Billing Agency',
  editBatch: 'Edit Batch',
  addBatch: 'Add Batch',
  createSampleSheet: 'Create Sample Sheet',
  seed: 'Seed',
  treatment: 'Treatment',
  openRisks: 'Open Risks',
  in_furrow: 'In Furrow',
  optimizePlans: 'Optimize Plans',
  planSummary: 'Plan Summary',
  fertility: 'Fertility',
  unit: 'Unit',
  units: 'Units',
  avgRate: 'Avg Rate',
  seedResistance: 'Seed Resistance',
  select: 'Select',
  good: 'Good',
  exc: 'Exc',
  resistanceOptions: 'Resistance Options',
  addProduct: 'Add Product',
  optimizedPlansSuccessMsg: 'Plans optimized successfully!',
  optimizedPlansErrorMsg: 'Error optimizing plans. Please refresh and try again.',
  optimizingPlans: 'Optimizing Plans',
  arrivedLab: 'Arrived Lab',
  blendedLab: 'Blended Lab',
  dealerPortal: 'Dealer Portal',
  deletePDFMsg: 'Are you sure you want to delete this PDF?',
  deletePDFErrorMsg: 'Error deleting PDF. Please refresh and try again.',
  deletePDFSuccessMsg: 'PDF deleted successfully!',
  PDFCropSelectMsg: 'If no crops selected, only the upcoming crop will be shown for each field.',
  PDFFieldsDisabledMsg: 'Fields will be disabled if results do not exist yet.',
  cotton: 'Cotton',
  getStartedCreateAccount: 'To get started, create your farming account',
  inviteNewUser: 'Invite New User',
  inviteUserMsg:
    'The user you are inviting does not have an account with Pattern yet. Please provide some more information about them to complete the invite',
  fieldsWithErrors: 'Fields with an * are required.',
  billingResponsibility: 'Billing Responsibility',
  noUserWithBillingResMsg:
    'There is no user with billing responsibility for this account. You will be able to create sample plans for this account, but will not be able collect samples in field until a user has been added here.',
  addingBillingResponsibility: 'Adding Billing Responsibility',
  omAnalysis: 'OM Analysis',
  pipeline: 'Pipeline',
  extractionMethod: 'Extraction Method',
  allExtractions: 'All Extractions',
  allPipelines: 'All Pipelines',
  modifiedMehlich: 'Modified Mehlich',
  mehlich: 'Mehlich',
  addSeedChart:
    'Select a hybrid or variety that will provide an adequate level of protection. Adding hybrids to your Product Catalog will display their protection levels here.',
  addProductChart:
    'Select a product that will provide an adequate level of protection. Adding products to your Product Catalog will display their protection levels here.',
  analyticAttributes: 'Analytic Attributes',
  analyticTaxonomies: 'Analytic Taxonomies',
  referencesAndResources: 'References and Resources',
  analyticImagery: 'Analytic Imagery',
  showInUI: 'Show in UI',
  analyticName: 'Analytic Name',
  category: 'Category',
  environmentalConsiderations: 'Environmental Considerations',
  backToAnalytics: 'Back to Analytics',
  addReferencesOrResources: 'Add References or Resources',
  referenceLink: 'Reference Link',
  uploadAnalyticImagery: 'Upload Analytic Imagery',
  imageLabel: 'Image Label',
  addNewImage: 'Add New Image',
  manageAnalytics: 'Manage Analytics',
  newAnalytic: 'New Analytic',
  crops: 'Crops',
  is_overview_nd: 'Is Overview ND',
  is_field_nd: 'Is Field ND',
  is_sample_nd: 'Is Sample ND',
  is_field_result_binary: 'Is Field Result Binary',
  countries: 'Countries',
  displayOrder: 'Display Order',
  displayCategory: 'Display Category',
  addAnalytic: 'Add Analytic',
  removeAnalyticFromView: 'Remove this analytic from view?',
  analyticOrderUpdated: 'Analytic order updated!',
  seedMissing: 'Hybrid options have not been added to product catalog. Click to review',
  treatmentMissing:
    'Seed treatment options have not been added to product catalog. Click to review',
  in_furrowMissing:
    'In-furrow protection options have not been added to product catalog. Click to review',
  foliarMissing:
    'Foliar protection options have not been added to product catalog. Click to review',
  fieldResultsOverview: 'Field Results - Overview',
  copyFromCorn: 'Copy from Corn',
  doNotBill: 'Do Not Bill',
  notSentToNetsuiteMsg: 'Operation sampling plans will not be billed in NetSuite.',
  analysisAndSampling: 'Analysis & Sampling',
  brazilPotassium: 'Brazil - Potassium',
  targetPotassium: 'Target Potassium',
  pro10Res: 'Pro (10x10 meter resolution)',
  zymoSilicaDescription: 'zymo lysis and silica filter isolation',
  omniRevvityPbWashDescription: 'omni lysis and magbead with PB wash',
  zymoRevvityDescription: 'zymo lysis and magbead with TBD method for chelating metal ions',
  omniRevvityDescription: 'omni lysis and magbead with TBD method for chelating metal ions',
  lockPoints: 'Lock Points',
  soilProcessing: 'Soil Processing',
  wet: 'Wet',
  dry: 'Dry',
  selectZones: 'Select Zones',
  generatePoints: 'Generate Points',
  setupZonesInstructionsMsg:
    'Set up zones for Bio analysis, then generate points for Nutrient analysis and Pro scanning',
  nextStep: 'Next Step',
  chooseAnalysisMsg: 'Choose the analysis you want ordered',
  pro_sensor_scan: 'Pro Sensor Scan',
  pro_map_generation: 'Pro Map Generation',
  canScanProPlans: '360 Pro Scanning Enabled',
  userScanningUpdated: 'User scanning capability updated!',
  soilCollection: 'Soil Collection',
  soilScan: 'Soil Scan',
  history: 'History',
  scanHistory: 'Scan History',
  dataReview: 'Data Review',
  nutrientPoints: 'Nutrient Points',
  resetPoints: 'Reset Points',
  minimalNumberPointsMsg: 'Please add more points in order to submit the plan.',
  editPoints: 'Edit Points',
  selfScanning: 'Self Scanning',
  scannedBy: 'Scanned By',
  toBeScannedBy: 'To be scanned by',
  waitingToScan: 'Waiting to Scan',
  readyToScan: 'Ready to Scan',
  planNames: 'Plan Name(s)',
  viewScanCoverage: 'View Scan Coverage',
  totalScanCoverage: 'Total Scan Coverage',
  tillMapper: 'TillMapper',
  tillage_map_generation: 'Tillage Map Generation',
  commission_tillage_map_generation: 'Commission Tillage Map Generation',
  tillage_measurement: 'Tillage Measurement',
  collectionsToReview: 'collections to review',
  collectionDate: 'Collection Date',
  collectionName: 'Collection Name',
  scanningTime: 'Scanning Time',
  confirmFieldAssignment: 'Confirm Field Assignment',
  noDataToReview: 'No Data To Review',
  fieldCoverage: 'Field Coverage',
  minutesAbbreviated: 'min',
  confirmCollectionMsg1:
    'This step will link the selected sample plans to the scan data listed underneath them.',
  confirmCollectionMsg2:
    'Please ensure that all of the necessary scan data has been uploaded for each sample plan before confirming and wait to confirm a sample plan if it has not been completely scanned.',
  tillMapperPoints: 'TillMapper Points',
  deletingCollection: 'Deleting collection...',
  collectionDeleted: 'Collection deleted.',
  deleteCollection: 'Delete Collection',
  deleteCollectionConfirm: 'Are you sure? This collection cannot be recovered once deleted.',
  tillMapperDensityMsg: 'TillMapper density must be greater than 8 and less than 10.5.',
  proResults: 'Pro Results',
  pointResults: 'Point Results',
  silver: 'Silver',
  gold: 'Gold',
  platinum: 'Platinum',
  region: 'Region',
  sampleOnly: 'Sample Only',
  samplePlusScan: 'Sample + Scan',
  assignSamplersSuccessMsg: 'Samplers/Scanners assigned successfully!',
  wheat: 'Wheat',
  secondaryAnalytic: 'Secondary Analytic',
  secondaryAnalyticMsg: 'Select an analytic that acts as a backup for the current analytic.',
  analysis590Required:
    'Warning: 590 Analysis Required - Process through dry / grind to support downstream analysis',
  analysis590Title: '590 Manure Management Analysis',
  analysis590Description:
    'This will include additional analysis in support of 590 Manure Management plans',
};

export default ENGLISH_STRINGS;
