import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Center, Group, Loader, Button, Stack, Space, Text } from '@mantine/core';
import { TabbedController } from 'common';
import { RootState } from 'store';
import showToast from 'actions/toastActions';
import { getAllAnalytics, getAnalyticById } from 'store/analytics/thunks';
import { requestGetAnalyticCropConfig, requestUpdateAnalytic } from 'store/analytics/requests';
import { AnalyticType } from 'store/analytics/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

import TaxonomiesEditor from './Sections/TaxonomiesEditor';
import ReferencesAndResources from './Sections/ReferencesAndResources';
import AnalyticImagery from './Sections/AnalyticImagery';
import AnalyticAttributes from './Sections/AnalyticAttributes';
import { FiArrowLeft } from 'react-icons/fi';
import { receiveAnalyticSortOrder, receiveSingleAnalytic } from 'store/analytics/actions';

const defaultAnalytic = {
  name: '',
  id: 0,
  category: 'pathogens',
  created_at: null,
  updated_at: null,
  show_in_ui: true,
  description: null,
  benchmarks: [],
  kegg_orthologs: [],
  environment_and_weather_notes: null,
  potential_yield_impact_notes: null,
  taxonomies: [],
  images: [],
  crops: [],
  display_config: {},
};

const EditAnalyticContainer = () => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const [analytic, setAnalytic] = useState<AnalyticType>(defaultAnalytic);
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, toggleIsSaving] = useState(false);
  const dispatch = useDispatch();
  const { id: analyticId } = useParams<{
    id: string;
  }>();

  const numAnalyticID = Number(analyticId);

  const { byId, isFetching } = useSelector((state: RootState) => state.analytics);
  const analyticTabs = [
    'analyticAttributes',
    'analyticTaxonomies',
    'referencesAndResources',
    'analyticImagery',
  ].map((tab) => ({ displayName: getString(tab, language) }));

  useEffect(() => {
    if (numAnalyticID) {
      dispatch(getAnalyticById(numAnalyticID));
    }
  }, [numAnalyticID]);

  useEffect(() => {
    setAnalytic(byId[numAnalyticID]);
    return () => {
      setAnalytic(defaultAnalytic);
    };
  }, [numAnalyticID, byId]);

  useEffect(() => {
    if (!isFetching && byId[numAnalyticID]) {
      setIsLoading(false);
    }
  }, [isFetching, byId, numAnalyticID]);

  useEffect(() => {
    dispatch(getAllAnalytics());
  }, []);

  const setAttribute = (key, value) => {
    setAnalytic((stateAnalytic) => ({
      ...stateAnalytic,
      [key]: value,
    }));
  };

  const showMessage = (message: string, type = '') => dispatch(showToast(message, type));

  const saveForm = async () => {
    toggleIsSaving(true);
    setIsLoading(true);
    try {
      if (numAnalyticID) {
        const newAnalytic = await requestUpdateAnalytic(numAnalyticID, {
          ...analytic,
          name: analytic.untranslated_name,
        });
        dispatch(receiveSingleAnalytic(newAnalytic));
      }
      const newConfig = await requestGetAnalyticCropConfig();
      dispatch(receiveAnalyticSortOrder(newConfig));
      // TODO create Analytics attributes
      navigate('/product/analytics');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      toggleIsSaving(false);
      setIsLoading(false);
    }
  };

  const cancelForm = () => {
    navigate(`/product/analytics`);
  };

  const displayedAnalyticAttributes = () => {
    switch (tabIndex) {
      case 1:
        return (
          <TaxonomiesEditor
            analytic={analytic}
            setAttribute={setAttribute}
            onError={(msg) => showMessage(msg.toString(), 'error')}
            onSuccess={showMessage}
          />
        );
      case 2:
        return (
          <ReferencesAndResources
            analytic={analytic}
            setAttribute={setAttribute}
            loadingError={''}
          />
        );
      case 3:
        return (
          <AnalyticImagery
            analytic={analytic}
            setAttribute={setAttribute}
            onError={(msg) => showMessage(msg.toString(), 'error')}
            onSuccess={showMessage}
            isSaving={isSaving}
          />
        );
      default:
        return <AnalyticAttributes analytic={analytic} setAttribute={setAttribute} />;
    }
  };

  return isLoading ? (
    <Center h="70vh">
      <Loader />
    </Center>
  ) : (
    <Stack gap="md">
      <Button
        w="12rem"
        variant="outline"
        onClick={() => navigate('/product/analytics')}
        leftSection={<FiArrowLeft />}
      >
        {getString('backToAnalytics', language)}
      </Button>
      <Text
        size="xl"
        fw={600}
      >{`${getString('analyticDetails', language)} - ${analytic?.name}`}</Text>
      <Group justify="space-between">
        <TabbedController activeIndex={tabIndex} onChange={setTabIndex} options={analyticTabs} />
        <Group>
          <Button loading={isSaving} onClick={saveForm}>
            {getString('save', language)}
          </Button>
          <Button variant="outline" onClick={cancelForm}>
            {getString('cancel', language)}
          </Button>
        </Group>
      </Group>
      <Space h="2rem" />
      <Stack>{displayedAnalyticAttributes()}</Stack>
    </Stack>
  );
};

export default EditAnalyticContainer;
