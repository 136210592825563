import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Center,
  Group,
  Loader,
  Select,
  Stack,
  Image,
  NumberInput,
  Popover,
  Paper,
  Text,
} from '@mantine/core';
import { TabbedController, Header } from 'common';
import { getFieldGeometry } from 'store/fields/thunks';
import { SampleType } from 'store/fields/types';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import GeoJSON from 'geojson';
import {
  analyticPreferenceList,
  getCategoryFromParams,
  getAnalysisViewOptions,
  getSubCategoryAnalyticsV2,
} from 'util/results';
import { RX, ANALYTIC_DISPLAY_CATEGORIES, CROP_PROTECTION } from 'constants/results';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { getPlanName, getSortedPlansWithResults, mergePlanAnalytics } from 'util/samplePlan';
import getSamplesForField from 'store/samples/thunks';
import { filterSamplesWithPlan } from 'util/sample';
import { getAnalyticsForCategory, getAnalyticsToShowFieldSummary } from 'util/chartUtils';

import { AnalyticType } from 'store/analytics/types';
import styles from './Container.module.css';
import MapbookList from './MapbookList';
import PrescriptionList from './Prescriptions/PrescriptionList';
import { getSamplePlanRecommendations } from 'store/recommendations/thunks';
import { CORN } from 'constants/variables';
import {
  filterAnalyticsPerTabByCrop,
  getCropImage,
  getOtherCrops,
} from 'util/overviewResultsDisplay';
import { getLatestCropPlan } from 'util/cropPlans';
import { putPostCropTargetYield } from 'store/cropPlans/thunks';

type paramsType = {
  operationId: string;
  fieldId: string;
  planId: string;
  analysis: 'crop-protection' | 'bioactive' | 'nutrients' | 'bcsr' | 'rx';
};

const FieldSummaryContainer = () => {
  const { operationId, fieldId, analysis, planId } = useParams<paramsType>();
  const { search } = useLocation();
  const cropFromUrl = new URLSearchParams(search).get('crop') || CORN;
  const navigate = useNavigate();
  const numericOperationId = Number(operationId);
  const numericFieldId = Number(fieldId);
  const numericPlanId = Number(planId);
  const browserLang = useBroswerLanguage();
  const language = new URLSearchParams(search).get('language') || browserLang;
  const dispatch = useDispatch();
  const {
    analytics,
    analyticCategories,
    operation,
    fieldGeometry,
    samples,
    hasFetchedSamples,
    hasFetchedFieldGeometry,
    recommendations,
    analyticSortOrder,
  } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[numericOperationId],
    fieldGeometry: state.fieldGeometry.geometries[numericFieldId],
    analytics: state.analytics.analytics,
    analyticCategories: state.analytics.analyticCategories,
    samples: state.samples.samples[numericFieldId],
    hasFetchedSamples: state.samples.hasFetchedList.includes(numericFieldId),
    hasFetchedFieldGeometry: state.fieldGeometry.hasFetchedList.includes(numericFieldId),
    recommendations: state.recommendations.byPlanId[numericPlanId],
    analyticSortOrder: state.analytics.analyticSortOrder,
  }));

  const preferredAnalytics = analyticPreferenceList(operation, analytics);
  const cropPlan = getLatestCropPlan(fieldGeometry);

  const [targetYield, setTargetYield] = useState<number | string>('');

  const analysisViewOptions = getAnalysisViewOptions(language, ANALYTIC_DISPLAY_CATEGORIES, false);

  useEffect(() => {
    if (numericPlanId) {
      dispatch(getSamplePlanRecommendations(numericPlanId));
    }
  }, [numericPlanId]);

  useEffect(() => {
    if (cropPlan) {
      setTargetYield(cropPlan.target_yield_per_acre);
    }
  }, [cropPlan]);

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(numericOperationId));
      dispatch(getFieldGeometry(numericFieldId));
    } else {
      dispatch(setActiveOperation(operation.id));
    }
  }, [operation, numericFieldId]);

  const sortedPlans = fieldGeometry?.features[0].properties
    ? getSortedPlansWithResults(fieldGeometry.features[0].properties, analyticCategories)
    : [];

  const selectedPlan = sortedPlans.find((plan) => plan.id === Number(planId));
  const secondaryPlan = sortedPlans.find(
    (plan) => plan.crop_year === selectedPlan?.crop_year && plan.id !== selectedPlan?.id,
  );

  const samplePlanOptions = sortedPlans.map((plan, idx) => ({
    id: idx,
    displayName: getPlanName(plan),
    label: getPlanName(plan),
    value: String(plan.id),
  }));

  const showAnotherPlan = (optionVal: string) =>
    navigate(
      `/results/field/${operationId}/${fieldId}/${optionVal}/${analysis}?crop=${cropFromUrl}&language=${language}`,
    );

  useEffect(() => {
    if (samplePlanOptions.length && !selectedPlan) {
      showAnotherPlan(samplePlanOptions[0].value);
    }
  }, [samplePlanOptions, selectedPlan, showAnotherPlan]);

  useEffect(() => {
    dispatch(getSamplesForField(numericFieldId, analytics));
  }, [numericFieldId, analytics]);

  const category = getCategoryFromParams(analysis);

  const mergedAnalytics = mergePlanAnalytics(selectedPlan, secondaryPlan);
  const analyticsInField = selectedPlan
    ? Object.keys(mergedAnalytics).flatMap((cat) => Object.keys(mergedAnalytics[cat]))
    : [];

  const isRx = analysis === RX;

  const { analyticsPerTab: analyticsToShow } = useMemo(
    () =>
      getSubCategoryAnalyticsV2(
        preferredAnalytics,
        [fieldGeometry],
        analyticSortOrder?.[analysis || CROP_PROTECTION] || {},
      ),
    [category, analysis, analyticCategories, preferredAnalytics, fieldGeometry, analyticSortOrder],
  );

  const analyticsPerTab = analyticsToShow.filter((analytic: AnalyticType) =>
    analyticsInField.includes(String(analytic.id)),
  );

  const fieldHasResults = getAnalyticsForCategory(
    mergedAnalytics,
    analyticsPerTab.map((analytic) => Number(analytic.id)),
  ).length;

  const handlePrint = () => {
    // wait for state change to propagate
    setTimeout(() => {
      window.print();
    }, 600);

    window.onafterprint = () => {
      window.onafterprint = null;
    };
  };

  const setCropTypeOnPlan = (crop: string, target?: string | number) => {
    dispatch(
      putPostCropTargetYield(language, numericFieldId, crop, cropPlan?.id, target, () =>
        navigate(
          `/results/field/${operation.id}/${fieldId}/${planId}/${analysis}?crop=${crop}&language=${language}`,
        ),
      ),
    );
  };

  const hasNoResults = (selectedPlan && !fieldHasResults) || !Number(planId);
  const isLoading = !hasFetchedFieldGeometry || !selectedPlan || !hasFetchedSamples;

  const maplistResults = (() => {
    const filteredAnalytics = filterAnalyticsPerTabByCrop(
      analyticsPerTab,
      mergedAnalytics,
      analyticSortOrder[analysis || CROP_PROTECTION][cropFromUrl],
    );
    if ((!isLoading || !Number(planId)) && (hasNoResults || !filteredAnalytics.length)) {
      return (
        <Center h={200} data-test-id="noResultsExistForField">
          <Text size="xl" fw={600}>
            {getString('noResultsExistForField', language)}
          </Text>
        </Center>
      );
    }
    if (isLoading) {
      return (
        <Center h={200}>
          <Loader />
        </Center>
      );
    }

    const analyticsInSelected = getAnalyticsForCategory(
      selectedPlan?.analytics,
      analyticsPerTab.map((analytic) => Number(analytic.id)),
    );
    const planWithResults = analyticsInSelected.length ? selectedPlan : secondaryPlan;
    const analyticsDisplayed = getAnalyticsToShowFieldSummary(planWithResults, filteredAnalytics);
    if (planWithResults) {
      return isRx ? (
        <PrescriptionList
          analytics={analyticsDisplayed}
          fieldGeometry={fieldGeometry}
          samplingPlan={planWithResults}
        />
      ) : (
        <MapbookList
          samplingPlan={planWithResults}
          filteredSamples={
            filterSamplesWithPlan(samples, planWithResults) as GeoJSON.Feature<
              GeoJSON.Geometry,
              SampleType
            >[]
          }
          fieldGeometry={fieldGeometry}
          analyticsPerTab={analyticsDisplayed}
          recommendations={recommendations}
          language={language}
        />
      );
    }
  })();

  return (
    <Stack>
      <Group>
        <Button
          size="compact-sm"
          variant="outline"
          onClick={() => {
            navigate(`/results/operation/${operation.id}/${analysis}`);
          }}
          className={styles.HideForPrint}
        >
          {getString('backToResults', language)}
        </Button>
      </Group>
      <Stack>
        <Header title={`${operation?.name} | ${fieldGeometry?.features[0].properties.name}`}>
          {samplePlanOptions.length > 0 && (
            <Select
              value={planId}
              onChange={(val) => val && showAnotherPlan(val)}
              data={samplePlanOptions}
            />
          )}
          <Button variant="outline" className={styles.HideForPrint} onClick={handlePrint}>
            {getString('print', language)}
          </Button>
        </Header>
        <TabbedController
          className={styles.TabbedController}
          options={analysisViewOptions}
          onChange={(idx: number) => {
            const newAnalysis = analysisViewOptions[idx].value;
            navigate(
              `/results/field/${operationId}/${fieldId}/${planId}/${newAnalysis}?crop=${cropFromUrl}&language=${language}`,
            );
          }}
          activeIndex={analysisViewOptions.findIndex((s) => s.value === analysis)}
        />
        <Group>
          <Text fw={700}>{getString('plannedCrop', language)}:</Text>
          <Popover width="auto" trapFocus position="bottom" withArrow shadow="md">
            <Popover.Target>
              <Paper shadow="sm" withBorder className={styles.CropImage}>
                <Center h="3rem">
                  <Image h="2rem" w="auto" src={getCropImage(cropPlan?.crop)} />
                </Center>
              </Paper>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap="sm">
                {getOtherCrops(cropPlan?.crop).map((crop) => (
                  <Image
                    key={crop}
                    h="2rem"
                    w="auto"
                    src={getCropImage(crop)}
                    className={styles.CropImgBox}
                    onClick={() => setCropTypeOnPlan(crop)}
                  />
                ))}
              </Group>
            </Popover.Dropdown>
          </Popover>
          <Text fw={700}>{getString('targetYield', language)}:</Text>
          <NumberInput
            hideControls
            value={targetYield}
            onBlur={() => setCropTypeOnPlan(cropPlan?.crop || CORN, targetYield)}
            onChange={setTargetYield}
            size="xs"
          />
        </Group>
      </Stack>
      {maplistResults}
    </Stack>
  );
};

export default FieldSummaryContainer;
