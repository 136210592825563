import React, { useEffect, useState } from 'react';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { AnalyticType } from 'store/analytics/types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack,
  Grid,
  Text,
  Loader,
  Image,
  Paper,
  Center,
  Popover,
  Group,
  Button,
  NumberInput,
} from '@mantine/core';
import { OperationFieldType } from 'store/operation/types';
import { getPlanName, getSamplingPlanWithResultsForAnalytics } from 'util/samplePlan';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getCropImage, getOtherCrops } from 'util/overviewResultsDisplay';
import { RX } from 'constants/results';
import { useDispatch } from 'react-redux';
import { CORN } from 'constants/variables';
import { getLatestCropPlan } from 'util/cropPlans';
import AnalyticChart from './AnalyticChart';
import Styles from './FieldRow.module.css';
import { putPostCropTargetYield } from 'store/cropPlans/thunks';
import classNames from 'classnames';

type FieldRowPropsType = {
  categorySubs: AnalyticType[];
  field: OperationFieldType;
  fieldGeometry: FieldType | undefined;
  samplingPlans?: SamplingPlanType[];
  cropType: string;
  openChartModal: (
    field: FieldType,
    analytic: AnalyticType | null,
    samplingPlan: SamplingPlanType | undefined,
    risk: string,
    isFoliarWithResults: boolean | undefined,
  ) => void;
  hasFailedGeometry: boolean;
  isFetchingGeometry: boolean;
  willFetch: boolean;
  analyticLength: number;
  cropRef: any;
  isPrinting: boolean;
};

type paramsType = {
  operationId: string;
  analysis: 'crop-protection' | 'bioactive' | 'nutrients' | 'rx';
};

const FieldRow = ({
  categorySubs,
  field,
  fieldGeometry,
  samplingPlans,
  openChartModal,
  hasFailedGeometry,
  isFetchingGeometry,
  willFetch,
  cropType,
  analyticLength,
  cropRef,
  isPrinting,
}: FieldRowPropsType) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { operationId, analysis } = useParams<paramsType>();

  const samplingPlan = getSamplingPlanWithResultsForAnalytics(
    samplingPlans,
    categorySubs.map((analytic) => analytic.id),
  );

  const displayed = samplingPlan ? categorySubs : [];

  const cropPlan = getLatestCropPlan(fieldGeometry);
  const [targetYield, setTargetYield] = useState<number | string>('');

  useEffect(() => {
    if (cropPlan) {
      setTargetYield(cropPlan.target_yield_per_acre);
    }
  }, [cropPlan]);

  const setCropTypeOnPlan = async (crop: string, target?: string | number) => {
    dispatch(putPostCropTargetYield(language, field.id, crop, cropPlan?.id, target));
  };

  if (!fieldGeometry && !isFetchingGeometry && !hasFailedGeometry && !willFetch) {
    return <></>;
  }

  return (
    <Grid gutter="xs" columns={analyticLength} ref={cropRef} className={Styles.WrapRow}>
      <Grid.Col
        span={2}
        onClick={() =>
          samplingPlan &&
          navigate(
            `/results/field/${operationId}/${samplingPlan?.field_id}/${samplingPlan?.id}/${analysis}?crop=${cropType}`,
          )
        }
      >
        <Stack gap={0} justify="center">
          <Text size="xs" fw={700} className={Styles.FieldName}>
            {field.farm_name ? `${field.farm_name}: ${field.name}` : field.name}
          </Text>
          {samplingPlan && (
            <Text size="0.7rem">
              {`${getString(
                samplingPlan.sampled_at ? 'sampled' : 'created',
                language,
              )}: ${getPlanName(samplingPlan)}`}
            </Text>
          )}
        </Stack>
      </Grid.Col>
      <Grid.Col span={1}>
        <Popover width="auto" trapFocus position="bottom" withArrow shadow="md">
          <Popover.Target>
            <Paper shadow={isPrinting ? 'none' : 'sm'} withBorder className={Styles.CropSelect}>
              <Center h="3rem">
                <Image h="auto" w="60%" src={getCropImage(cropPlan?.crop)} />
              </Center>
            </Paper>
          </Popover.Target>
          <Popover.Dropdown>
            <Group gap="sm">
              {getOtherCrops(cropType).map((crop) => (
                <Image
                  key={crop}
                  h="2rem"
                  w="auto"
                  src={getCropImage(crop)}
                  className={Styles.CropImgBox}
                  onClick={() => setCropTypeOnPlan(crop)}
                />
              ))}
            </Group>
          </Popover.Dropdown>
        </Popover>
      </Grid.Col>
      <Grid.Col span={1}>
        <Paper
          shadow="sm"
          withBorder
          className={classNames(Styles.CropSelect, Styles.HideWhilePrinting)}
        >
          <NumberInput
            hideControls
            value={targetYield}
            onBlur={() => setCropTypeOnPlan(cropType || CORN, targetYield)}
            onChange={setTargetYield}
            size="xs"
            className={Styles.HideWhilePrinting}
            classNames={{
              input: Styles.CropInput,
            }}
          />
        </Paper>
        <Center>
          <Text size="xs" className={Styles.ShowWhilePrinting}>
            {targetYield}
          </Text>
        </Center>
      </Grid.Col>
      {fieldGeometry ? (
        displayed.map((analytic) => (
          <Grid.Col span={1} key={analytic.id}>
            <AnalyticChart
              field={fieldGeometry}
              activeAnalytic={analytic}
              samplingPlan={samplingPlan}
              openChartModal={openChartModal}
              isPrinting={isPrinting}
            />
          </Grid.Col>
        ))
      ) : (
        <Grid.Col span={1}>
          {hasFailedGeometry ? (
            <p className={Styles.FailedP}>{getString('failed', language)}</p>
          ) : (
            <Loader size="sm" data-test-id="field-row-spinner" />
          )}
        </Grid.Col>
      )}
      {analysis === RX && (
        <Grid.Col span={1}>
          <Center h="3rem" w="8rem">
            <Button
              data-test-id="create-rx"
              onClick={() => navigate(`/results/rx/${operationId}/${field.id}/create`)}
            >
              {getString('create', language)} Rx
            </Button>
          </Center>
        </Grid.Col>
      )}
    </Grid>
  );
};

export default FieldRow;
