import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  Group,
  Input,
  MultiSelect,
  SegmentedControl,
  Select,
  Stack,
  Switch,
  Textarea,
  Text,
  Tooltip,
} from '@mantine/core';
import { AnalyticType } from 'store/analytics/types';
import {
  BINARY,
  categoryDisplayNames,
  CONTINOUOS,
  DISPLAY_ANALYTICS_TOGGLES,
} from 'constants/results';
import { Typeahead } from 'common/Components/Mantine/Typeahead';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { COUNTRY_OPTIONS } from 'constants/countries';
import styles from '../Container.module.css';
import { FiSearch } from 'react-icons/fi';

type AnalyticAttributesPropsType = {
  analytic?: AnalyticType;
  setAttribute: (attributeName: string, attributeValue: any) => void;
};

const AnalyticAttributes = ({ analytic, setAttribute }: AnalyticAttributesPropsType) => {
  const language = useBroswerLanguage();
  const [viewSecondaryAnalytic, setViewSecondaryAnalytic] = useState<string | null>(null);

  const { analyticCategories, allAnalytics } = useSelector((state: RootState) => {
    return {
      analyticCategories: state.analytics.analyticCategories,
      allAnalytics: state.analytics.allAnalytics,
    };
  });

  const handleTextChangeSecondary = (value: string) => {
    setViewSecondaryAnalytic(value);
  };

  const analyticsDisplayed = allAnalytics
    .filter((a) => a.show_in_ui)
    .map((a) => ({
      label: a.untranslated_name || '',
      value: String(a.id),
      ...a,
    }));

  useEffect(() => {
    if (analytic?.display_config?.secondary_analytic_id) {
      const selectedAnalytic = analyticsDisplayed.find(
        (option) => option.id === analytic?.display_config?.secondary_analytic_id,
      );
      setViewSecondaryAnalytic(selectedAnalytic?.label || '');
    }
  }, [allAnalytics, analytic?.display_config]);

  const handleUserSelection = (val: number | string) => {
    const selectedAnalytic = analyticsDisplayed.find((option) => option.id === Number(val));
    setAttribute('display_config', {
      ...analytic?.display_config,
      secondary_analytic_id: selectedAnalytic ? Number(val) : null,
    });
    setViewSecondaryAnalytic(selectedAnalytic?.label || '');
  };

  const analyticCategoryOptions = analyticCategories.map((cat) => ({
    id: cat,
    label: categoryDisplayNames[cat],
    value: cat,
  }));

  return (
    <Stack gap="xl">
      <Group justify="space-between" align="flex-start">
        <Input.Wrapper w="18rem" label={getString('analyticName', language)}>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAttribute('untranslated_name', e.target.value)
            }
            value={analytic?.untranslated_name || ''}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('category', language)}>
          <Select
            data={analyticCategoryOptions}
            placeholder="Select a category"
            value={analyticCategoryOptions.find((c) => c.id === analytic?.category)?.value}
            onChange={(_, option) => setAttribute('category', option.value)}
          />
        </Input.Wrapper>
        <Input.Wrapper
          label={
            <Tooltip label={getString('secondaryAnalyticMsg', language)} color="blue">
              <Text>{getString('secondaryAnalytic', language)}</Text>
            </Tooltip>
          }
        >
          <Typeahead
            onTextChange={handleTextChangeSecondary}
            onSelect={handleUserSelection}
            onDeselect={() => handleUserSelection('')}
            data={analyticsDisplayed}
            data-test-id="secodn-analytic-select-bar"
            placeholder={getString('searchSampler', language)}
            leftSection={<FiSearch />}
            value={viewSecondaryAnalytic || ''}
            w="20rem"
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('showInUI', language)}>
          <Switch
            checked={analytic?.show_in_ui}
            className={styles.Toggle}
            onChange={() => setAttribute('show_in_ui', !analytic?.show_in_ui)}
          />
        </Input.Wrapper>
      </Group>
      <Group justify="space-between" align="flex-start">
        {DISPLAY_ANALYTICS_TOGGLES.map((ndVal) => (
          <Input.Wrapper label={getString(ndVal, language)} key={ndVal}>
            <Switch
              checked={analytic?.display_config?.[ndVal] || false}
              className={styles.Toggle}
              onChange={() =>
                setAttribute('display_config', {
                  ...analytic?.display_config,
                  [ndVal]: !analytic?.display_config?.[ndVal],
                })
              }
            />
          </Input.Wrapper>
        ))}
        <Stack gap={0}>
          <Text size="sm" fw={500} mt={0}>
            {getString('fieldResultsOverview', language)}
          </Text>
          <SegmentedControl
            data={[CONTINOUOS, BINARY]}
            value={analytic?.display_config?.is_field_result_binary ? BINARY : CONTINOUOS}
            onChange={() =>
              setAttribute('display_config', {
                ...analytic?.display_config,
                is_field_result_binary: !analytic?.display_config?.is_field_result_binary,
              })
            }
          />
        </Stack>
        <MultiSelect
          label={getString('countries', language)}
          w="15rem"
          data={COUNTRY_OPTIONS}
          value={analytic?.display_config?.countries || []}
          onChange={(countries) =>
            setAttribute('display_config', { ...analytic?.display_config, countries })
          }
        />
      </Group>
      <Group justify="space-between" align="flex-start">
        <Input.Wrapper label={getString('yieldImpact', language)}>
          <Textarea
            onChange={(e) => setAttribute('potential_yield_impact_notes', e.target.value)}
            value={analytic?.potential_yield_impact_notes || ''}
            w="35rem"
            autosize
            maxRows={5}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('environmentalConsiderations', language)}>
          <Textarea
            onChange={(e) => setAttribute('environment_and_weather_notes', e.target.value)}
            value={analytic?.environment_and_weather_notes || ''}
            w="35rem"
            autosize
            maxRows={5}
          />
        </Input.Wrapper>
      </Group>
    </Stack>
  );
};

export default AnalyticAttributes;
